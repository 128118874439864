import { format } from "date-fns";
import { useState } from "react";
import type { auditType, checklistAuditChanges, todoType } from "@/types";

import arrowdown from "@/assets/img/arrow_chevron_down.svg";
import arrowUp from "@/assets/img/arrow-chevron_up.svg";
import { formatDate } from "@/helper";

interface Props {
    audit: auditType[0];
    showType: boolean;
}

type itemKeyType = Pick<todoType[0], "ceremony" | "due" | "note">;
const trackChecklistChangesArray = ["Ceremony", "Due", "Note"];

export default function ChecklistAuditDropdown({ audit, showType }: Props) {
    const [dropdown, setDropdown] = useState(false);

    const _audit_date = new Date(audit.timestamp);
    const audit_time = format(_audit_date, "dd-MM-yyyy, KK:mm aa");
    const action =
        audit.action === "delete_checklist" ?
            "deleted" :
            audit.action === "checklist_completed" ?
                "marked as completed" :
                audit.action === "checklist_not_completed" ?
                    "changed from completed to available" :
                    audit.action === "add_checklist" ?
                        "added" :
                        "updated";

    function toggleDropdown() {
        setDropdown((prev) => !prev);
    }

    const arrow_image_src = dropdown ? arrowUp : arrowdown;

    const dropdownAudit = audit.action === "add_checklist" || audit.action === "update_checklist";

    const auditClassname = !dropdownAudit ? "my-3" : "mb-";
    const auditHeaderClassname = !dropdown ? "mb-3" : "";
    const auditBodyClassname = !dropdown ? "" : "mb-3";

    function formatAuditDate(givenDate: Date) {
        const formatDateDue = givenDate ? formatDate(givenDate) : "NO DEADLINE";
        return formatDateDue;
    }

    function displayChanges(title: string, key: keyof todoType[0], depthKey: keyof todoType[0]["ceremony"] | null) {
        const auditChanges = audit?.changes as checklistAuditChanges;
        const auditDepthData = auditChanges?.from && depthKey && auditChanges.from.ceremony[depthKey];

        const auditFromData = depthKey && title === "Ceremony" ? auditDepthData : auditChanges?.from && auditChanges?.from[key];

        const auditToData =
            depthKey && title === "Ceremony" ? auditChanges?.to && auditChanges?.to.ceremony[depthKey] : auditChanges?.to && auditChanges?.to[key];
        const dueDateFrom = auditChanges?.from?.due as Date;
        const dueDateTo = auditChanges?.to?.due as Date;

        const auditFromDataResultData = title === "Due" ? formatAuditDate(dueDateFrom) : auditFromData;
        const auditFromDataResult = auditFromDataResultData as string;

        const auditToDataResultData = title === "Due" ? formatAuditDate(dueDateTo) : auditToData;
        const auditToDataResult = auditToDataResultData as string;

        return (
            (auditFromData || auditToData) && (
                <p key={key} className="mb-1">
                    {title}: {auditChanges?.from && auditFromData !== auditToData && <span className="underline mr-2">{auditFromDataResult}</span>}
                    {auditChanges?.to && auditChanges?.to[key] && <span> {auditToDataResult}</span>}
                </p>
            )
        );
    }

    const auditTypeClassname = showType ? "show_type" : "";

    return (
        <>
            <div
                className={`${auditClassname} ${auditHeaderClassname} ${auditTypeClassname}
            audit_list_card bg-white p-3 rounded justify-content-between d-flex align-items-lg-center`}>
                <div className="audit_details">
                    <span className="font-weight-bold mr-1">{audit.title}</span> was
                    <span className="font-weight-bold mx-1">{action}</span>
                    by {audit?.name ? audit.name : audit.email} at {audit_time}
                </div>
                <div className="audit_type_group">
                    {showType && <div className="type">{audit.type}</div>}
                    {dropdownAudit && (
                        <button className="border-0 bg-transparent" onClick={toggleDropdown}>
                            <img src={arrow_image_src} alt="dropdown icon" />
                        </button>
                    )}
                </div>
            </div>
            {dropdown && (
                <div className={`${auditBodyClassname} dropdown checklist_dropdown bg-white px-3 pb-2 mb-3`}>
                    {trackChecklistChangesArray.map((item) => {
                        const _itemKey = item.toLowerCase() as unknown;
                        const itemKey = _itemKey as keyof itemKeyType;
                        const itemDepthKey = item === "Ceremony" ? "name" : null;
                        return displayChanges(item, itemKey, itemDepthKey);
                    })}
                </div>
            )}
        </>
    );
}
