import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from "react-toastify";
import Popover from "react-bootstrap/Popover";
import { Suspense } from "react";

import AddNewUserIcon from "@/assets/icon/AddNewUserIcon";
import ArrowRightIcon from "@/assets/icon/ArrowRightIcon";
import ArrowleftIcon from "@/assets/icon/ArrowleftIcon";
import PencilIcon from "@/assets/icon/PencilIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import useEventDetails from "@/hooks/useEventDetails";
import { useModal } from "@/hooks/useModal";
import { getFirstTwoChar } from "@/utils/getFirstChar";
import Eventweather from "@/components/Eventweather";
import type { eventType } from "@/types";
import Loader from "./loader";


interface Props {
    event: eventType| null;
    userEmail: string;
}

export default function EventDetails({ event, userEmail }: Props) {
    const { setModal, setModalDetails } = useModal();
    const {
        currentCeremony,
        currentEventColorCode,
        eventCeremonyLocation,
        dateCountDown,
        ceremonyDate,
        arrangedCollaborators,
        disablePrev,
        disableNext,
        ceremonyPeriod,
        incrementEventHandler,
        decrementEventHandler,
    } = useEventDetails(event, userEmail);

    function openCeremonyModal() {
        if (event?.ceremonies.length === 7) {
            return toast.error("You can only add maximum of seven ceremonies to this event");
        }
        setModal("add_ceremony_modal");
    }

    function editCeremonyModal(ceremonyId: string) {
        setModal("edit_ceremony_modal");
        setModalDetails({ ceremonyId });
    }

    function openPartnerModal() {
        setModal("collaborator_modal");
    }

    function openCollaboratorModal() {
        setModal("show_event_collaborator");
    }

    const style = {
        background: `linear-gradient(237deg, ${currentEventColorCode} 0%, #545565 100%)`,
    };

    const numberOfCeremonies = event?.ceremonies ? event?.ceremonies.length : 0;
    const event_view_style = numberOfCeremonies > 1 ? "grid" : "";

    return (
        <div className="new_event_details d-flex flex-column mt-5 mb-md-4 mb-2">
            <div
                className={`weather_view ${event_view_style} top d-flex align-items-center justify-content-between py-3 px-md-3 px-2`}
                style={style}>
                <div className="event_title d-flex align-items-lg-center col-5 px-0 col-md-7 pl-2 flex-column flex-md-row">
                    <h3 className="mb-0 mr-md-5 ceremony_name">{currentCeremony?.name}</h3>
                    {currentCeremony?.location && <Suspense fallback={<Loader />}>
                        <Eventweather currentCeremony={currentCeremony} />
                    </Suspense> }
                </div>
                {event?.ceremonies && event?.ceremonies.length > 1 && (
                    <div className="ml-lg-4" id="manage_ceremonies">
                        <button
                            className="event_control bg-transparent border-0 mx-lg-3 mx-md-2 mx-1"
                            onClick={decrementEventHandler}
                            disabled={disablePrev}>
                            <ArrowleftIcon />
                        </button>
                        <button
                            data-testid="next_ceremony_control"
                            className="event_control bg-transparent border-0 mx-md-3 mx-2"
                            onClick={incrementEventHandler}
                            disabled={disableNext}
                        >
                            <ArrowRightIcon />
                        </button>
                    </div>
                )}
                <div className="event_icons mr-2 mr-md-0">
                    <button
                        id="edit_ceremony_button"
                        className="bg-transparent border-0"
                        onClick={() => editCeremonyModal(currentCeremony?.id as string)}>
                        <PencilIcon />
                    </button>
                    <button id="add_ceremony_button" className="ml-2 bg-transparent border-0" onClick={openCeremonyModal}>
                        <PlusIcon fill="#fff" />
                    </button>
                </div>
            </div>
            <div className="body d-flex flex-column flex-md-row justify-content-between bg-white px-4 py-2">
                <div className="section_1 d-flex justify-content-between border-b-0 w-100 px-0 py-3">
                    <div className="col-5 px-0">
                        <h6 className="mb-0">{dateCountDown}</h6>
                        <p className="mb-1">{ceremonyPeriod}</p>
                        <p className="date">{ceremonyDate}</p>
                    </div>
                    <div className="col-3 px-0">
                        <h6 className="mb-0">Location</h6>
                        <p className="location">{eventCeremonyLocation ? eventCeremonyLocation : "-"}</p>
                    </div>
                    <div className="col-3 px-lg-0">
                        <h6 className="mb-0">Guests</h6>
                        <p className="guest">{currentCeremony?.no_of_guests ? currentCeremony?.no_of_guests : "-"}</p>
                    </div>
                </div>
                <div className="collaborators px-0 col-12 col-xl-3 col-lg-4 col-md-4 pl-xl-4 ml-md-4 py-3">
                    <h6>Collaborators</h6>
                    <div className="d-flex position-relative align-items-md-center mt-3">
                        {arrangedCollaborators.slice(0, 2).map((collaborator, index) => {
                            const collaboratorIcon = collaborator.email ? getFirstTwoChar(collaborator.email) : "";
                            const className = collaborator.email && index === arrangedCollaborators.length - 1 ? "mr-3" : "";
                            const className2 = index !== 0 ? "join" : "";

                            return (
                                collaboratorIcon && (
                                    <OverlayTrigger
                                        key={index}
                                        placement="top"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <Popover.Title as="h3">
                                                    <p>{collaborator.email}</p>
                                                </Popover.Title>
                                                <Popover.Content>
                                                    <p>Role - {collaborator.role}</p>
                                                </Popover.Content>
                                            </Popover>
                                        }>
                                        <div className="circle_wrapper">
                                            <div key={index} className={`circle font-weight-bold ${className} ${className2}`}>
                                                {collaboratorIcon}
                                            </div>
                                            <div className="comment_icon">
                                                <svg
                                                    width="14px"
                                                    height="14px"
                                                    viewBox="0 0 32 32"
                                                    version="1.1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    xmlnsXlink="http://www.w3.org/1999/xlink"
                                                    fill="#fff">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                                                    <g id="SVGRepo_iconCarrier">
                                                        {" "}
                                                        <defs> </defs>{" "}
                                                        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            {" "}
                                                            <g id="icon_set_filled" transform="translate(-102.000000, -257.000000)" fill="#fff">
                                                                {" "}
                                                                <path
                                                                    // eslint-disable-next-line max-len
                                                                    d="M118,257 C109.164,257 102,263.269 102,271 C102,275.419 104.345,279.354 108,281.919 L108,289 L115.009,284.747 C115.979,284.907 116.977,285 118,285 C126.836,285 134,278.732 134,271 C134,263.269 126.836,257 118,257"
                                                                    id="comment-1">
                                                                    {" "}
                                                                </path>{" "}
                                                            </g>{" "}
                                                        </g>{" "}
                                                    </g>
                                                </svg>
                                            </div>
                                        </div>
                                    </OverlayTrigger>
                                )
                            );
                        })}
                        {arrangedCollaborators.length > 2 && (
                            <button
                                className="border-0 bg-transparent ml-1 mr-4 d-flex align-items-center"
                                title="Show all collaborators"
                                data-testid="add_collaborator"
                                onClick={openCollaboratorModal}>
                                <PlusIcon fill="black" /> <span className="font-weight-bold mx-2">{arrangedCollaborators.length - 2}</span>
                            </button>
                        )}
                        {event?.event_owner?.email === userEmail && (
                            <button id="invite_collaborator_button" className="border-0 bg-transparent" onClick={openPartnerModal}>
                                <AddNewUserIcon />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
