import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import Marquee from "react-fast-marquee";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import useScrollPosition from "@/hooks/useScrollPosition";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import planadayNewLogo from "@/assets/img/planaday_logo.svg";
import planadayDashboard from "@/assets/img/planaday-dashboard.webp";
import instagramIcon from "@/assets/img/instagram_new.svg";
import emailIcon from "@/assets/img/email_icon.svg";
import tiktokIcon from "@/assets/img/tiktok_new.svg";
import twitterIcon from "@/assets/img/twitter_icon.svg";
import infoIcon from "@/assets/img/info_icon.svg";
import coupleIcon from "@/assets/img/couple.webp";
import plannerIcon from "@/assets/img/planner.webp";
import vendorassistanceImg from "@/assets/img/vendor_assistance_img.webp";
import budgetImg from "@/assets/img/budget_img.webp";
import costestimateImg from "@/assets/img/cost_estimate.webp";
import instagramredIcon from "@/assets/img/instagram_red.svg";
import tiktokredIcon from "@/assets/img/tiktok_red.svg";
import planadaywhiteIcon from "@/assets/img/planaday_logo_white.png";
import emailredIcon from "@/assets/img/email_red.svg";
import upwardarrowIcon from "@/assets/img/upward_arrow.png";
import checklistImg from "@/assets/img/checklist.webp";
import aseobiOrderFormImg from "@/assets/img/asoebi_orderform.webp";
import mailImg from "@/assets/img/mail.webp";
import homepageheroBanner1 from "@/assets/img/homepage_hero.webp";
import homepageheroBanner2 from "@/assets/img/banner_img_2.webp";
import homepageheroBanner3 from "@/assets/img/banner_img_3.webp";
import homepageheroBanner4 from "@/assets/img/banner_img_4.webp";
import { testimonials, testimonials2 } from "@/utils/testimonial";
import "@/styles/landing_page.scss";

const weddingText = ["wedding planning", "budget tracking", "floor plan design"];

function heroTextClassName(counter: number) {
    switch (counter) {
    case 0:
        return "animate__fadeInLeftBig";
    case 1:
        return "animate__fadeInLeft";
    case 2:
        return "animate__fadeInRight";
    }
}

export default function LandingPage() {
    const scrollPosition = useScrollPosition();
    const mobile = useMediaQuery("(max-width: 768px)");
    const [counter, setCounter] = useState(0);
    const [showMenu, setShowMenu] = useState(false);
    const showSocialIcons = mobile ? scrollPosition < 5700 : scrollPosition < 7200;

    useEffect(() => {
        const heroTextInterval = setInterval(() => {
            if (counter < weddingText.length - 1) {
                setCounter((prev) => prev + 1);
            } else {
                setCounter(0);
            }
        }, 4000);

        return () => clearInterval(heroTextInterval);
    }, [counter]);

    const heroClassName = heroTextClassName(counter);
    const menuClassName = showMenu ? "open" : "close";

    function toggleMenuHandler() {
        setShowMenu((prev) => !prev);
    }

    const mobileMenuClassname = showMenu ? "animate__fadeInLeft": "animate__fadeInRight";

    return (
        <>
            <header className="d-flex header landing_page">
                <div>
                    <div className="group_1">
                        <Link to="/new/landing-page">
                            <img src={planadayNewLogo} />
                        </Link>
                        <ul>
                            <li>
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">Features</Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li>
                                <a target="_blank" href="https://www.blog.planaday.events/">Community</a>
                            </li>
                            <li>
                                <Link to="/new/about-us">About</Link>
                            </li>
                        </ul>
                    </div>
                    <button aria-label="Menu" onClick={toggleMenuHandler} className={`mobile_menu ${menuClassName} hamburger`}>
                        <span className="hamburger-top"></span>
                        <span className="hamburger-middle"></span>
                        <span className="hamburger-bottom"></span>
                    </button>
                    <div className="group_2">
                        <Link to="/login">Sign in</Link>
                        <Link className="px-3 button" to="/signup">Start for free</Link>
                    </div>
                </div>
            </header>
            {showMenu && (
                <aside className={`mobileMenu animate__animated ${mobileMenuClassname}`}>
                    <ul>
                        <li>
                            <Dropdown>
                                <Dropdown.Toggle id="dropdown-basic">Features</Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Option 1</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Option 2</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li>
                            <a target="_blank" href="https://www.blog.planaday.events/">Community</a>
                        </li>
                        <li>
                            <Link to="/new/about-us">About</Link>
                        </li>
                    </ul>
                    <Link to="/login">Sign in</Link>
                    <Link className="px-3 button btn mt-4" to="/signup">Start for free</Link>
                </aside>
            )}
            <main className="main landing_page" id="top">
                <section className="hero">
                    <div className="hero_carousel_content">
                        <div className="overlay" />
                        <Carousel autoPlay infiniteLoop showThumbs={false} showIndicators={false} showArrows={false} showStatus={false}>
                            <div>
                                <img className="hero_img" src={homepageheroBanner1} />
                            </div>
                            <div>
                                <img className="hero_img" src={homepageheroBanner2} />
                            </div>
                            <div>
                                <img className="hero_img" src={homepageheroBanner3} />
                            </div>
                            <div>
                                <img className="hero_img" src={homepageheroBanner4} />
                            </div>
                        </Carousel>
                        <div className="content_wrapper">
                            <div className="hero_content">
                                <div className="emoji_wrapper py-1">
                                    <p>Introducing Planaday ✨</p>
                                </div>
                                <h1>
									Stress-free <span className={`animate__animated ${heroClassName}`}>{weddingText[counter]}</span>
                                </h1>
                                <h2>
									Free tools for effortless planning: <br />
									Your wedding, Your budget, Uniquely yours
                                </h2>
                                <div className="button_group d-flex align-items-center">
                                    <button className="left">Talk to us</button>
                                    <Link to="/signup" className="right button">✨Start for free✨</Link>
                                </div>
                            </div>
                        </div>
                        {showSocialIcons && (
                            <ul className="socials">
                                <li>
                                    <a href="https://www.instagram.com/planaday_weddings/" target="__blank">
                                        <img src={instagramIcon} alt="Instagram" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.tiktok.com/@planaday" target="__blank">
                                        <img src={tiktokIcon} alt="Tiktok" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@planaday.events" target="__blank">
                                        <img src={emailIcon} alt="Email" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/planadaywedding?s=21" target="__blank">
                                        <img src={twitterIcon} alt="Twitter icon" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@planaday.events" target="__blank">
                                        <img src={infoIcon} alt="info icon" />
                                    </a>
                                </li>
                            </ul>
                        )}
                        <div className="dashboard_shadow"></div>
                    </div>
                    <div className="dashboard_image_wrapper">
                        <img className="dashboard_image" src={planadayDashboard} />
                    </div>
                </section>
                <section className="text_content_first">
                    <h3>
						We support you at every <span>phase.</span>
                    </h3>
                    <div className="col-md-8 col-12 mx-auto section_group">
                        <div className="section_card">
                            <div>
                                <img className="card_img" src={coupleIcon} />
                                <span>Free to use</span>
                                <h4>Engaged Couple</h4>
                                <div className="text_content">
                                    <p>Recently engaged and intrigued about costs? – see estimates in one click!</p>
                                    <Link to="#">
                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                // eslint-disable-next-line max-len
                                                d="M0.359863 6.98423C0.359863 6.63077 0.646401 6.34423 0.999863 6.34423L12.2548 6.34423L7.58732 1.67678C7.33738 1.42684 7.33738 1.02162 7.58732 0.77168C7.83725 0.521745 8.24248 0.521745 8.49241 0.77168L14.2524 6.53168C14.5023 6.78162 14.5023 7.18684 14.2524 7.43678L8.49241 13.1968C8.24248 13.4467 7.83725 13.4467 7.58731 13.1968C7.33738 12.9468 7.33738 12.5416 7.58731 12.2917L12.2548 7.62423L0.999863 7.62423C0.646401 7.62423 0.359863 7.33769 0.359863 6.98423Z"
                                                fill="#303030"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="section_card">
                            <div>
                                <img className="card_img" src={plannerIcon} />
                                <span>Beta testing ongoing</span>
                                <h4>Events Planner</h4>
                                <div className="text_content">
                                    <p>Our built-in migration tools smoothen your process – making it easy to multiple events of different types.</p>
                                    <Link to="#">
                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                // eslint-disable-next-line max-len
                                                d="M0.359863 6.98423C0.359863 6.63077 0.646401 6.34423 0.999863 6.34423L12.2548 6.34423L7.58732 1.67678C7.33738 1.42684 7.33738 1.02162 7.58732 0.77168C7.83725 0.521745 8.24248 0.521745 8.49241 0.77168L14.2524 6.53168C14.5023 6.78162 14.5023 7.18684 14.2524 7.43678L8.49241 13.1968C8.24248 13.4467 7.83725 13.4467 7.58731 13.1968C7.33738 12.9468 7.33738 12.5416 7.58731 12.2917L12.2548 7.62423L0.999863 7.62423C0.646401 7.62423 0.359863 7.33769 0.359863 6.98423Z"
                                                fill="#303030"
                                            />
                                        </svg>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="bg_img_container"></div>
                <div className="features_group">
                    <h3>
						Our <span>Features</span>
                    </h3>
                    <section className="features">
                        <div>
                            <h3>
								Find your dream <br /> <span>wedding vendors</span>
                            </h3>
                            <p>Connect with trusted professionals who will bring your dream to life.</p>
                            <div>
                                <button>Get vendor assistance</button>
                                <button>Become a vendor</button>
                            </div>
                            <img src={vendorassistanceImg} alt="Vendor assitance" />
                        </div>
                    </section>
                    <section className="features">
                        <h3>
							Create a <br /> <span>personalised budget.</span>
                        </h3>
                        <div>
                            <p>Say goodbye to financial stress, create a budget that signs with your vision and your wallet.</p>
                            <div>
                                <Link to="/">Calculate my budget</Link>
                            </div>
                            <img src={budgetImg} alt="Budget estimate" />
                        </div>
                    </section>
                    <section className="features">
                        <h3>
							Manage your money <br /> <span>with our budget tracker.</span>
                        </h3>
                        <div>
                            <p>Say goodbye to financial stress, create a budget that aligns with your vision and your wallet.</p>
                            <div>
                                <Link to="/">Calculate my budget</Link>
                            </div>
                            <img src={costestimateImg} alt="Cost estimate" />
                        </div>
                    </section>
                    <section className="features">
                        <h3>
							Stay on track, <br /> <span>Never miss a beat</span>
                        </h3>
                        <div>
                            <p>Start a to-do list and stay on top of tasks on your checklist.</p>
                            <div>
                                <Link to="/">Calculate my budget</Link>
                            </div>
                            <img src={checklistImg} alt="Checklist" />
                        </div>
                    </section>
                    <section className="features">
                        <h3>
							Coordinate your Aso-ebi; <br /> <span>the stress-free way</span>
                        </h3>
                        <div>
                            <p>
								Our digital solution helps you manage the Asoebi journey for your event, adding ease to the purchase process for you
								and your guests.
                            </p>
                            <div>
                                <Link to="/">Calculate my budget</Link>
                            </div>
                            <img src={aseobiOrderFormImg} alt="Asoebi orderform" />
                        </div>
                    </section>
                </div>
                <section className="join_community d-flex justify-content-between">
                    <div>
                        <h5>Join our community</h5>
                        <p>Exclusive offers, deals and content. Say what?! Get all the latest on your fave products and brands.</p>
                        <button>Talk to us</button>
                    </div>
                    <img className="mt-4" src={mailImg} />
                </section>
                <section className="features_grou testimonials">
                    <h3>Testimonials</h3>
                    <Marquee pauseOnHover className="testimonial_group" direction="left">
                        {testimonials.map((testimonial, index) => (
                            <div key={index} className="customer_testimonial">
                                <div className="card_top">
                                    <img src={testimonial.img} />
                                    <div>
                                        <h5>{testimonial.name}</h5>
                                        <p>{testimonial.role}</p>
                                    </div>
                                </div>
                                <p className="mt-4">"{testimonial.text}"</p>
                            </div>
                        ))}
                    </Marquee>
                    <Marquee pauseOnHover className="testimonial_group" direction="right">
                        {testimonials2.map((testimonial, index) => (
                            <div key={index} className="customer_testimonial">
                                <div className="card_top">
                                    <img src={testimonial.img} />
                                    <div>
                                        <h5>{testimonial.name}</h5>
                                        <p>{testimonial.role}</p>
                                    </div>
                                </div>
                                <p className="mt-4">"{testimonial.text}"</p>
                            </div>
                        ))}
                    </Marquee>
                </section>
            </main>
            <footer className="footer landing_page">
                <div className="top_section">
                    <img src={planadaywhiteIcon} alt="logo" />
                    <div className="footer_icon_group">
                        <div>
                            <p>Couples</p>
                            <p>Event Planners</p>
                        </div>
                        <div>
                            <a href="#top" className="go_up">
                                <img src={upwardarrowIcon} alt="go up" />
                            </a>
                            <a href="https://www.instagram.com/planaday_weddings/" target="__blank" className="icon">
                                <img src={instagramredIcon} alt="Instagram" />
                            </a>
                            <a href="https://www.tiktok.com/@planaday" target="__blank" className="icon">
                                <img src={tiktokredIcon} alt="Tiktok" />
                            </a>
                            <a href="https://twitter.com/planadaywedding?s=21" target="__blank" className="icon">
                                <img src={twitterIcon} alt="Twitter" />
                            </a>
                            <a href="https://mail.google.com/mail/?view=cm&fs=1&to=info@planaday.events" target="__blank" className="icon">
                                <img src={emailredIcon} alt="Mail us" />
                            </a>
                        </div>
                    </div>
                </div>
                <hr className="my-0" />
                <div className="bottom_section">
                    <p>© Planaday 2024. All rights reserved.</p>
                    <div>
                        <Link target="_blank" to="/new/privacy-policy">Privacy Policy</Link>
                        <Link target="_blank" to="/new/term-of-service">Terms of Service</Link>
                    </div>
                </div>
            </footer>
        </>
    );
}
