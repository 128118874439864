import { initializeApp } from "firebase/app";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef, useState } from "react";
import {
    GoogleAuthProvider,
    getAuth,
    setPersistence,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    FacebookAuthProvider,
    signOut,
    User,
    confirmPasswordReset,
    updatePassword,
    sendPasswordResetEmail,
    browserLocalPersistence,
    connectAuthEmulator,
} from "firebase/auth";
import { toast } from "react-toastify";

import { firebaseConfig } from "@/helpers/firebase-config";
import useToast from "@/hooks/useToast";
import { saveNewUser, onboardCollaborator, checkUserStatus } from "@/axios/post-request";
import { updateOnboardingDetails } from "@/axios/put-request";
import { getRandomQuoteIndex } from "@/utils/getRandomQuote";
import firebaseErrorMessages from "@/json/firebaseErrorMessages.json";
import { AuthEmulatorURL, LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { getUserDetails } from "@/axios/get-request";

export const app = initializeApp(firebaseConfig);
connectToAuthEmulatorHandler();

type inviteDetailsType = {
    inviter_id?: string | null;
    invitee_role?: string | null;
    inviter_email?: string | null;
    event_id?: string | null;
    user_type?: "event_planner" | "celebrant";
};

type errorType = {
    code: string;
    message?: string;
};

export function getFirebaseErrorMessage(error: errorType) {
    const _firebaseErrorMessages = firebaseErrorMessages as {
        [key: string]: string;
    };
    const errorKeys = Object.keys(firebaseErrorMessages);
    const errorMessage = errorKeys.includes(error.code) ? _firebaseErrorMessages[error.code] : error?.message;
    const message = errorMessage || "An error occurred, try again later!";
    return message;
}

function connectToAuthEmulatorHandler() {
    if (process.env.NODE_ENV !== "production") {
        connectAuthEmulator(getAuth(app), AuthEmulatorURL);
    }
}

export default function useAuth() {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const { loadingToast, updateToast } = useToast();
    const toastID = useRef("auth");

    async function googleAuth(inviteDetails: inviteDetailsType, onboardingUrl = "/onboarding/event-details") {
        const dashboardUrl = location.pathname.includes("/new") ? "/new/dashboard" : "/dashboard/events";
        setLoading(true);
        loadingToast(toastID);
        const auth = getAuth(app);
        const provider = new GoogleAuthProvider();
        setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithPopup(auth, provider)
                .then(async (result) => {
                    const user = result?.user;
                    const userStatusRequest = await checkUserStatus(user.uid as string);
                    if (!userStatusRequest.data.result) {
                        await saveNewUserToDB(user, inviteDetails, onboardingUrl);
                    } else {
                        if (inviteDetails.event_id && inviteDetails.inviter_id) {
                            await onboardCollaborator(user.uid, inviteDetails.event_id, inviteDetails.inviter_id)
                                .then(() => {
                                    setLoading(false);
                                    navigate(dashboardUrl);
                                    updateToast(toastID, "success", `Welcome, ${user.email}`);
                                })
                                .catch((error) => {
                                    setLoading(false);
                                    updateToast(toastID, "error", error?.message || "An error occurred, try again later!");
                                });
                        }
                        navigate(dashboardUrl);
                        setLoading(false);
                        updateToast(toastID, "success", `Welcome, ${user.email}`);
                        getRandomQuoteIndex();
                    }
                })
                .catch((error) => {
                    console.log("error", error);
                    const errorMessage = getFirebaseErrorMessage(error);

                    if (inviteDetails.invitee_role && errorMessage) {
                        navigate("/login");
                    } else {
                        // EMAIL_EXISTS
                        setLoading(false);
                        updateToast(toastID, "error", errorMessage);
                    }
                });
        });
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.onboarding_details);
    }

    async function facebookAuth() {
        const provider = new FacebookAuthProvider();
        const auth = getAuth();
        setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithPopup(auth, provider)
                .then((result) => {
                    const credential = FacebookAuthProvider.credentialFromResult(result);
                    const accessToken = credential?.accessToken;
                    window.localStorage.setItem("facebook_authtoken", JSON.stringify(accessToken));
                })
                .catch((error) => {
                    console.log("eror", error);
                    toast.error(error?.message || "An error occurred, try again later!");
                });
        });
    }

    async function signInWithUserEmailAndPassword(
        email: string,
        password: string,
        { inviter_id, invitee_role, event_id }: inviteDetailsType,
        dashboardUrl = "/dashboard/events",
    ) {
        setLoading(true);
        loadingToast(toastID);
        const auth = getAuth();
        setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithEmailAndPassword(auth, email, password)
                .then(async (result) => {
                    const user = result?.user;
                    const userDetails = await getUserDetails();
                    if (!userDetails?.result) {
                        return updateToast(toastID, "error", "User does not exist");
                    }
                    if (!inviter_id && !userDetails.result?.business_name && location?.search?.includes("event_planner")) {
                        return navigate("/new/onboarding-event-planner/stage-1");
                    }

                    getRandomQuoteIndex();

                    if (inviter_id && invitee_role && event_id) {
                        return await onboardCollaborator(user.uid, event_id, inviter_id)
                            .then(() => {
                                setLoading(false);
                                updateToast(toastID, "success", `Welcome, ${email}`);
                                navigate(dashboardUrl);
                            })
                            .catch((error) => {
                                setLoading(false);
                                updateToast(toastID, "error", error?.message || "An error occurred, try again later!");
                            });
                    } else {
                        updateToast(toastID, "success", "Log in successful");
                        setLoading(false);
                        return navigate(dashboardUrl);
                    }
                })
                .catch((error) => {
                    const errorMessage = getFirebaseErrorMessage(error);
                    setLoading(false);
                    return updateToast(toastID, "error", errorMessage);
                })
                .finally(() => setLoading(false));
        });
    }

    async function saveNewUserToDB(user: User, inviteDetails: inviteDetailsType, onboardingUrl: string) {
        const dashboardUrl = location.pathname.includes("/new") ? "/new/dashboard" : "/dashboard/events";
        try {
            await saveNewUser({
                uid: user.uid,
                email: user.email,
                email_verified: user.emailVerified,
                photo_url: user.photoURL,
                display_name: user.displayName,
                phone_number: user.phoneNumber,
                user_type: inviteDetails.user_type ?? "",
            });

            // onboard new user that is not a collaborator
            if (!inviteDetails?.inviter_id) {
                await updateOnboardingDetails(user.uid, {
                    id: user.uid,
                    completed: false,
                    invited: false,
                    user_name: user.displayName,
                    email: user.email,
                    onboarding_stage: 0,
                });
                setLoading(false);
                updateToast(toastID, "success", `Welcome, ${user.email}`);
                return navigate(onboardingUrl);
            } else {
                // onboard invited collaborator
                const event_id = inviteDetails.event_id as string;
                await onboardCollaborator(user.uid, event_id, inviteDetails.inviter_id);
                setLoading(false);
                updateToast(toastID, "success", `Welcome, ${user.email}`);
                navigate(dashboardUrl);
            }
        } catch (error: unknown) {
            const _error = error as { message: string };
            console.log("error", error);
            setLoading(false);
            updateToast(toastID, "error", _error?.message || "An error occurred, try again later!");
        }
    }

    async function createNewUserWithEmailAndPassword(
        email: string,
        password: string,
        inviteDetails: inviteDetailsType,
        onboardingUrl = "/onboarding/event-details",
    ) {
        setLoading(true);
        const auth = getAuth();
        loadingToast(toastID);
        setPersistence(auth, browserLocalPersistence).then(() => {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    const user = userCredential.user;
                    await saveNewUserToDB(user, inviteDetails, onboardingUrl);
                })
                .catch((error) => {
                    setLoading(true);
                    const errorMessage = getFirebaseErrorMessage(error);
                    if (inviteDetails.invitee_role) {
                        return signInWithUserEmailAndPassword(email, password, inviteDetails, onboardingUrl);
                    } else {
                        setLoading(false);
                        updateToast(toastID, "error", errorMessage);
                    }
                });
        });
        window.localStorage.removeItem(LOCAL_STORAGE_KEYS.onboarding_details);
    }

    async function createNewAsoebiAdminWithEmailAndPassword(email: string, password: string) {
        const auth = getAuth();
        return setPersistence(auth, browserLocalPersistence).then(() => createUserWithEmailAndPassword(auth, email, password));
    }

    async function signInAsoebiAdminWithEmailAndPassword(email: string, password: string) {
        setLoading(true);
        loadingToast(toastID);
        const auth = getAuth();
        return setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithEmailAndPassword(auth, email, password)
                .then(async () => {
                    updateToast(toastID, "success", "Log in successful");
                    setLoading(false);
                    navigate("/asoebi/dashboard");
                })
                .catch((error) => {
                    const errorMessage = getFirebaseErrorMessage(error);
                    setLoading(false);
                    updateToast(toastID, "error", errorMessage);
                })
                .finally(() => setLoading(false));
        });
    }

    function getAuthDetails() {
        const auth = getAuth(app);
        return auth;
    }

    async function signUserOut(url?: string) {
        const loginURl = url ? url : "/login";
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                toast.success("Log out successful");
                navigate(loginURl);
            })
            .catch((error) => {
                const errorMessage = getFirebaseErrorMessage(error);
                toast.error(errorMessage);
            });
    }

    async function asoebeiAdminSignOut() {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                toast.success("Log out successful");
                window.localStorage.removeItem(LOCAL_STORAGE_KEYS.planner_admin_email);
                navigate("/asoebi/login");
            })
            .catch((error) => {
                const errorMessage = getFirebaseErrorMessage(error);
                toast.error(errorMessage);
            });
    }

    async function updateUserProfile(password: string) {
        try {
            const auth = getAuth();
            const user = auth.currentUser;
            if (!user) return;
            return updatePassword(user, password);
        } catch (error: unknown) {
            const _error = error as errorType;
            const errorMessage = getFirebaseErrorMessage(_error);
            toast.error(errorMessage);
        }
    }

    async function confirmUserPasswordReset(code: string, password: string) {
        const auth = getAuth();
        return confirmPasswordReset(auth, code, password)
            .then(() => {
                toast.success("Password reset successful");
                navigate("/login");
            })
            .catch((error) => {
                const errorMessage = getFirebaseErrorMessage(error);
                toast.error(errorMessage);
            });
    }

    async function forgotPassword(email: string) {
        loadingToast(toastID);
        setLoading(true);
        const auth = getAuth(app);
        return sendPasswordResetEmail(auth, email)
            .then(() => {
                setLoading(false);
                updateToast(toastID, "success", "Password reset email sent");
            })
            .catch((error) => {
                setLoading(false);
                const errorMessage = getFirebaseErrorMessage(error);
                updateToast(toastID, "error", errorMessage);
            });
    }

    return {
        googleAuth,
        facebookAuth,
        signInWithUserEmailAndPassword,
        createNewUserWithEmailAndPassword,
        createNewAsoebiAdminWithEmailAndPassword,
        signInAsoebiAdminWithEmailAndPassword,
        signUserOut,
        forgotPassword,
        loading,
        asoebeiAdminSignOut,
        getAuthDetails,
        confirmUserPasswordReset,
        updateUserProfile,
    };
}
