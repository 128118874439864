import { formatDistanceStrict } from "date-fns";

import trash from "@/assets/img/trash.svg";
import editIcon from "@/assets/img/edit.svg";
import { formatDate } from "@/helper";
import Button from "@/components/Button";
import useChecklistItem from "@/hooks/useChecklistItem";
import { useFilter } from "@/hooks/useFilter";
import type { eventType } from "@/types";

interface Props {
    todoItem: {
        isDone: boolean;
        due: Date | null;
        id: string;
        name: string;
        ceremony: { name: string; id: string };
        note: string | null;
    };
    editable?: boolean;
    deleteable?: boolean;
    userData?: {
        name: string;
        id: string;
        email: string;
    };
    ceremoniesDateAndId?: Array<{ id: string; date: Date | null }>;
    activeEvent: eventType[] | null;
}

export default function ChecklistItem({ todoItem, editable = true,
    ceremoniesDateAndId, deleteable = true, activeEvent}: Props) {
    const { onCompleteTaskHandler, editModalHandler, deleteModalHandler } = useChecklistItem(todoItem, activeEvent);
    const formatDateDue = todoItem?.due ? formatDate(todoItem?.due) : "";
    const dateValue = todoItem?.due ? `DUE ${formatDateDue}` : "NO DEADLINE";
    const doneTask = todoItem.isDone ? "done" : "";
    const { ceremony } = useFilter();
    const ceremonyDate = ceremoniesDateAndId?.filter((item) => item.id === todoItem.ceremony.id)[0]?.date;
    const datePeriod = todoItem?.due && ceremonyDate ? formatDistanceStrict(new Date(todoItem?.due), new Date(ceremonyDate)) : "";
    const formatDatePeriod = datePeriod.toLowerCase().includes("seconds") ? "Ceremony day" : datePeriod;
    const ceremonyDateInstance = ceremonyDate ? new Date(ceremonyDate) : null;
    const todoItemDateInstance = todoItem?.due ? new Date(todoItem?.due) : null;
    const _formatDatePeriodRange =
        todoItemDateInstance && ceremonyDateInstance ? (ceremonyDateInstance > todoItemDateInstance ? "before ceremony" : "after ceremony") : null;
    const formatDatePeriodRange = datePeriod.toLowerCase().includes("seconds") ? "" : _formatDatePeriodRange;

    return (
        <div className={`todo-item ${doneTask}`}>
            <div className="todo_left d-flex align-items-center">
                <input type="checkbox" checked={todoItem.isDone} onChange={onCompleteTaskHandler} />
                <div className="text">
                    <div className="d-flex align-items-center">
                        <h5 className="mb-0 mt-1">{todoItem.name}</h5>
                        {editable && (
                            <button className="ml-2 mb-0 bg-white border-0" onClick={editModalHandler}>
                                <img className="pencil" src={editIcon} alt="Edit icon" />
                            </button>
                        )}
                    </div>
                    <div className="d-flex align-items-center date_group">
                        <p className="mb-0">{dateValue}</p>
                        {todoItem?.due && ceremonyDate && (
                            <div className="ml-md-4 mt-2 mb-0 future_date_tag">
                                {formatDatePeriod} {formatDatePeriodRange}
                            </div>
                        )}
                        {ceremony.id === "all" && todoItem?.ceremony?.name && (
                            <div className="ml-md-4 mt-2 mb-0 future_date_tag ceremony_name">
                                {todoItem.ceremony.name}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {deleteable && (
                <div className="icons d-flex flex-column">
                    <Button icon={trash} onClick={deleteModalHandler} className="ml-0" />
                </div>
            )}
        </div>
    );
}
