import { verifyMagicLink } from "@/axios/post-request";
import { RequestType } from "@/data/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function useAsoebiAdminAuth() {
    const [tokenValue, setTokenValue] = useState("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get("token") as string;

    const { data: verifyData } = useQuery({
        queryKey: ["verify_magic_link"],
        queryFn: () => verifyMagicLink(tokenValue),
        onSuccess: (data) => {
            if (token && data.data.type === RequestType.Success) {
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.magiclink_token, token);
            }
            if (data.data.type === RequestType.Error) {
                return navigate("/asoebi/login");
            }
        },
        onError: () => {
            return navigate("/asoebi/login");
        },
        enabled: !!tokenValue,
    });

    useEffect(() => {
        if (token) {
            return setTokenValue(token);
        }

        const magiclinkTokenStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.magiclink_token);

        if (magiclinkTokenStorage === "null" || !magiclinkTokenStorage) {
            return navigate("/asoebi/login");
        }

        if (magiclinkTokenStorage && !token) {
            return setTokenValue(magiclinkTokenStorage);
        }
    }, [token]);

    return { verifyData };
}
