import { useNavigate } from "react-router-dom";

import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import CheckIcon2 from "@/assets/icon/CheckIcon2";
import InfoIcon from "@/assets/icon/InfoIcon";
import "@/styles/auth_page.scss";
import "@/styles/onboarding_stages.scss";
import { useEvent } from "@/hooks/useEvent";

export default function OnboardingCelebrantStage3() {
    const navigate = useNavigate();
    const { selectedEventId } = useEvent();

    return (
        <NewOnboardingLayout className="onboarding_page_stage" text="Start planning your" text2="dream event today...">
            <div className="content d-flex flex-column auth">
                <div className="auth_content onboarding_stage">
                    <ul className="onboarding_stepper">
                        <li className="step_done">
                            <p>Step 1</p>
                            <div>
                                <CheckIcon2 height="6" width="6" />
                            </div>
                        </li>
                        <li className="step_done">
                            <p>Step 2</p>
                            <div>
                                <CheckIcon2 height="6" width="6" />
                            </div>
                        </li>
                        <li>
                            <p>Step 3</p>
                            <div>
                                <span className="active"></span>
                            </div>
                        </li>
                        <div className="hr_wrapper">
                            <hr />
                        </div>
                    </ul>
                    <div className="page_title">
                        <h4 className="mb-0">Budget Calculator 🧾</h4>
                        <p>No idea on what your budget should be?</p>
                    </div>
                    <div className="info_card">
                        <div>
                            <span>
                                <InfoIcon />
                            </span>
                            <div>
                                <p className="click_next">Click 'Next' to use our Budget Calculator</p>
                                <p>
                                    Have your budget breakdown ready to go? <br />
                                    <a className="mx-1" href="/new/celebrant/pricing">
                                        ‘Skip’
                                    </a>{" "}
                                    this step to complete your profile.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="button_group">
                    <button className="prev_btn" onClick={() => navigate("/new/onboarding-celebrant/stage-2")}>
                        Previous
                    </button>
                    <a className="underline_text" href="/new/celebrant/pricing">
                        <span>Skip</span> <br />
                        (I'll Do This Later)
                    </a>
                    <button className="next_btn" onClick={() => navigate(`/onboarding/calculate-event-budget?event_id=${selectedEventId}&mode=new`)}>
                        Next
                    </button>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
