import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";
import { generateRandomLinearGradientColor } from "@/utils/generateRandomColor";
import useAuth from "@/hooks/useAuth";
import { formatStringToNumber } from "@/helper";
import { addCustomerToPaystackRequest, createEvent, onboardUserDetails, sendSignupEmail, updateEventPictureRequest } from "@/axios/post-request";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus, EventPlanStatus, EventPlanType, PLAN_PAYMENT_TYPE, UserList, ceremonyType } from "@/types";
import { updateOnboardingDetails, updateUserDetails } from "@/axios/put-request";
import { useEvent } from "@/hooks/useEvent";
import useUser from "./useUser";

type onboardingDetailsType = {
    name: string;
    event_name: string;
    event_date: Date | null;
    ceremonies: Array<{
        type: string;
        location: string;
        date?: Date | string | null;
        no_of_guests: string;
    }>;
};

export default function useCelebrantOnboarding(file?: File | null) {
    const { getAuthDetails } = useAuth();
    const [userEventId, setUserEventId] = useState("");
    const { setSelectedEventId } = useEvent();
    const [loading, setLoading] = useState(false);
    const { data } = useUser();
    const navigate = useNavigate();

    async function onboardCelebrantHandler(onboardingDetails: onboardingDetailsType) {
        try {
            if (!onboardingDetails.name) {
                return toast.error("Name is required");
            }
            if (!onboardingDetails.event_name) {
                return toast.error("Event name is required");
            }
            if (!onboardingDetails.event_date) {
                return toast.error("Event Date is required");
            }
            if (onboardingDetails.ceremonies.length === 0) {
                return toast.error("Add at least one ceremony");
            }
            if (!onboardingDetails.ceremonies[0].type) {
                return toast.error("Ceremony type is required");
            }
            setLoading(true);
            const event_bg = generateRandomLinearGradientColor();
            const authDetails = getAuthDetails();
            const userId = authDetails?.currentUser?.uid as string;
            await onboardUserDetails(userId, {
                name: onboardingDetails.name,
                tour_guide: {
                    user_profile: false,
                    event_overview: false,
                    budget_tracker: false,
                    checklist: false,
                    floor_plan: false,
                    homepage: false,
                },
            });
            const eventId = userEventId ? userEventId : uuidv4();
            const ceremoniesArray = [] as ceremonyType;
            onboardingDetails.ceremonies.forEach((item) => {
                ceremoniesArray.push({
                    id: uuidv4(),
                    name: item.type,
                    location: item.location,
                    date: item?.date ? item.date as Date : null,
                    no_of_guests: item.no_of_guests,
                    checklist: [],
                    show_suggestion: true,
                });
            });
            const createEventRequest = await createEvent({
                id: eventId,
                location: onboardingDetails.ceremonies[0]?.location ?? "",
                event_name: onboardingDetails.event_name,
                event_date: onboardingDetails.event_date,
                no_of_guests: onboardingDetails.ceremonies[0]?.no_of_guests ? formatStringToNumber(onboardingDetails.ceremonies[0]?.no_of_guests) : 0,
                event_bg,
                ceremonies: ceremoniesArray,
                collaborators: [
                    {
                        id: authDetails?.currentUser?.uid as string,
                        email: authDetails?.currentUser?.email as string,
                        role: "Event Owner",
                        payment_plan: CollaboratorPaymentPlan.FREE,
                        event_ids: [eventId],
                        plan_duration: null,
                        send_plan_expiration_email: false,
                        plan_status: CollaboratorPlanStatus.ACTIVE,
                        start_plan_date: new Date().toISOString(),
                        end_plan_date: null,
                    },
                ],
                event_owner: {
                    email: authDetails?.currentUser?.email as string,
                    id: authDetails?.currentUser?.uid as string,
                    role: "Event Owner",
                    user_type: UserList.celebrant,
                    event_id: eventId,
                },
                plan: EventPlanType.FREE,
                plan_amount: null,
                plan_payment_type: PLAN_PAYMENT_TYPE.ONE_TIME_PAYMENT,
                plan_duration_month: null,
                plan_status: EventPlanStatus.ACTIVE,
                collaborator_per_events: 2,
                collaborator_ids: [userId],
                max_events: 3,
            });
            if (createEventRequest.status === 200) {
                setSelectedEventId(eventId);
                window.localStorage.setItem(LOCAL_STORAGE_KEYS.ceremonies, JSON.stringify(ceremoniesArray));
                const currentUserEmail = authDetails?.currentUser?.email as string;
                window.localStorage.setItem(
                    LOCAL_STORAGE_KEYS.onboarding_details,
                    JSON.stringify({
                        event_id: eventId,
                        location: onboardingDetails.ceremonies[0].location ?? "",
                        event_name: onboardingDetails.event_name,
                        event_date: onboardingDetails.event_date,
                        no_of_guests: formatStringToNumber(onboardingDetails.ceremonies[0].no_of_guests),
                        name: onboardingDetails.name,
                        ceremonies: onboardingDetails.ceremonies,
                    }),
                );

                await updateOnboardingDetails(userId, {
                    onboarding_details: {
                        event_id: eventId,
                        location: onboardingDetails.ceremonies[0]?.location ?? "",
                        event_name: onboardingDetails.event_name,
                        event_date: onboardingDetails.event_date ? onboardingDetails.event_date : "",
                        no_of_guests: formatStringToNumber(onboardingDetails.ceremonies[0].no_of_guests),
                        name: onboardingDetails.name,
                        ceremonies: onboardingDetails.ceremonies,
                    },
                    onboarding_stage: 1,
                    completed: false,
                });
                if (file) {
                    const formData = new FormData();
                    formData.append("file", file);
                    await updateEventPictureRequest(formData, eventId);
                }
                if (authDetails?.currentUser?.email) {
                    await addCustomerToPaystackRequest({ email: authDetails?.currentUser?.email, name: onboardingDetails.name });
                }
                if (!data?.result?.action?.signup_email_sent) {
                    const sendSignupEmailRequest = await sendSignupEmail(onboardingDetails.name, currentUserEmail);
                    if (sendSignupEmailRequest.status === 200) {
                        await updateUserDetails(userId, {
                            event_ids: [eventId],
                            user_type: UserList.celebrant,
                            action: { signup_email_sent: true },
                        });
                    }
                }
            }
            setLoading(false);
            return navigate("/new/onboarding-celebrant/stage-2");
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error("An error occured");
        }
    }

    return {
        loading,
        onboardCelebrantHandler,
        setUserEventId,
    };
}
