import { formatDistanceStrict, isFuture } from "date-fns";
import { useState, useEffect } from "react";
import { formatInTimeZone } from "date-fns-tz";

import { useFilter } from "@/hooks/useFilter";
import type { eventType } from "@/types";


export default function useEventDetails(event: eventType | null, userEmail: string) {
    const { setCeremony } = useFilter();
    const [ceremonyIndex, setCeremonyIndex] = useState(0);
    const currentCeremony = event?.ceremonies ? event?.ceremonies[ceremonyIndex] : null;
    const collaboratorsEmails: string[] = [];

    useEffect(() => {
        if (currentCeremony) {
            setCeremony({
                name: currentCeremony?.name,
                id: currentCeremony?.id,
            });
        }
    }, [ceremonyIndex]);

    const eventCeremonyLocation =
        currentCeremony?.location?.toLowerCase() === "mainland" || currentCeremony?.location?.toLowerCase() === "island" ?
            `Lagos ${currentCeremony?.location}` :
            currentCeremony?.location;

    const ceremonyDate = currentCeremony?.date ? formatInTimeZone(new Date(currentCeremony?.date), "Africa/Lagos", "dd-MM-yyyy") : null;
    const dateCountDown = currentCeremony?.date ? formatDistanceStrict(new Date(currentCeremony?.date), new Date(), { addSuffix: false }) : "";
    const isCeremonyPeriodFuture = currentCeremony?.date ? isFuture(new Date(currentCeremony?.date)) : "-";
    const _ceremonyPeriod =
        !isCeremonyPeriodFuture &&
        (dateCountDown.includes("hours") || dateCountDown.includes("seconds") || dateCountDown.includes("hour") || dateCountDown.includes("second")) ?
            "Congrats on your D-day!" :
            isCeremonyPeriodFuture ?
                "until your ceremony!" :
                "after your ceremony";
    const ceremonyPeriod = currentCeremony?.date ? _ceremonyPeriod : "No date set";
    const disablePrev = ceremonyIndex === 0 ? true : false;
    const disableNext = event?.ceremonies && ceremonyIndex === event?.ceremonies?.length - 1 ? true : false;

    function incrementEventHandler() {
        if (event?.ceremonies && ceremonyIndex < event?.ceremonies.length - 1) {
            setCeremonyIndex((prev) => prev + 1);
        }
    }
    function decrementEventHandler() {
        if (ceremonyIndex > 0) {
            setCeremonyIndex((prev) => prev - 1);
        }
    }

    const arrangedCollaborators: eventType["collaborators"] = [];

    if (event?.collaborators && event?.collaborators?.length > 0) {
        event.collaborators.map((collaborator) => {
            if (collaborator.email && collaborator.email !== userEmail) {
                arrangedCollaborators.push(collaborator);
            }
        });
    }

    const colorCodes = ["#DA334E", "rgb(31 127 176)", "#C0C6A7", "rgb(164 137 137)", "rgb(96 50 50)", "rgb(71 34 56)", "rgb(86 139 116)"];
    const currentEventColorCode = colorCodes[ceremonyIndex];
    arrangedCollaborators.map((collaborator) => collaboratorsEmails.push(collaborator.email));

    return {
        currentEventColorCode,
        currentCeremony,
        eventCeremonyLocation,
        incrementEventHandler,
        decrementEventHandler,
        ceremonyDate,
        dateCountDown,
        ceremonyPeriod,
        collaboratorsEmails,
        disablePrev,
        disableNext,
        arrangedCollaborators,
    };
}
