import { ChangeEvent, FormEvent, useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import DashboardModal from "@/components/modals/DashboardModal";
import Input2 from "@/components/form/Input2";
import onboardingFormContent from "@/json/form/onboarding_form.json";
// import InputDropdown from "@/components/form/InputDropdown";
import SelectDateInput from "@/components/form/SelectDateInput";
import formDetails from "@/json/create_event_form.json";
import Button from "@/components/Button";
import { formatNumber } from "@/helper";
import { useEvent } from "@/hooks/useEvent";
import { updateCeremony } from "@/axios/put-request";
import type { ceremonyType } from "@/types";
import NewCeremonyPicker from "../form/NewCeremonyPicker";

interface Props {
    onClose: () => void;
    activeCeremony: ceremonyType[0];
}

export default function EditEventCeremonyModal({ onClose, activeCeremony }: Props) {
    const { selectedEventId } = useEvent();
    const [ceremony, setCeremony] = useState<ceremonyType[0]>(activeCeremony);
    const [ceremonyPickerOptions, setCeremonyPickerOptions] = useState(onboardingFormContent.ceremonyOptions);
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    function updateCeremonyTypeHandler(__option: unknown) {
        const option = __option as { label: string; value: string; __isNew__?: boolean };
        setCeremony((prev) => ({
            ...prev,
            name: option?.label ? option?.label : "",
        }));
        if (option?.__isNew__) {
            setCeremonyPickerOptions([{ label: option?.label, value: option.value }, ...ceremonyPickerOptions]);
        }
    }

    function updateCeremonyHandler(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.name === "no_of_guests") {
            const guest_no_limit = formatNumber(event.target.value);
            return setCeremony({
                ...ceremony,
                no_of_guests: event.target.value ? guest_no_limit : "",
            });
        } else {
            return setCeremony({
                ...ceremony,
                [event.target.name]: event.target.value,
            });
        }
    }

    function updateCeremonyDateHandler(date: Date) {
        return setCeremony((prev) => ({
            ...prev,
            date,
        }));
    }

    async function onEditCeremonyHandler(event: FormEvent<HTMLFormElement>) {
        try {
            event.preventDefault();

            if (!ceremony.name) {
                return toast.error("Ceremony name is required");
            }
            if (!ceremony.location) {
                return toast.error("Ceremony location is required");
            }
            if (!ceremony.date) {
                return toast.error("Ceremony date is required");
            }
            if (!ceremony.no_of_guests) {
                return toast.error("Ceremony guest number is required");
            }

            setLoading(true);
            await updateCeremony(ceremony, activeCeremony.id, selectedEventId);
            setLoading(false);
            toast.success("Ceremony updated");
            queryClient.invalidateQueries();
            onClose();
        } catch (error) {
            setLoading(false);
            toast.error("Oops an error occured");
            console.log("error", error);
            onClose();
        }
    }

    return (
        <DashboardModal title="Edit Ceremony" description="Update event ceremony" className="edit_ceremony" onClose={onClose}>
            <form className="edit_event_ceremony_form d-flex flex-column" onSubmit={onEditCeremonyHandler}>
                <div className="ceremonies w-full">
                    {ceremony && (
                        <div className="ceremony_input_group  d-flex">
                            <NewCeremonyPicker
                                value={{ label: ceremony.name }}
                                defaultValue={{ label: ceremony.name, value: ceremony.name }}
                                options={ceremonyPickerOptions}
                                onChange={updateCeremonyTypeHandler}
                            />
                            <Input2
                                className="location_input"
                                input={formDetails.ceremony.location}
                                value={ceremony.location as string}
                                onChange={(event) => updateCeremonyHandler(event)}
                                type="text"
                            />
                            <SelectDateInput
                                className="ceremony_date"
                                label={formDetails.ceremony.date.label}
                                placeholder={formDetails.ceremony.date.placeholder}
                                value={ceremony.date}
                                containerClassName="input2"
                                onClick={(date) => updateCeremonyDateHandler(date)}
                            />
                            <Input2
                                className="select_input"
                                input={formDetails.ceremony.guestNumber}
                                value={ceremony.no_of_guests as number}
                                onChange={(event) => updateCeremonyHandler(event)}
                                type="text"
                            />
                        </div>
                    )}
                </div>
                <div className="button_group d-flex">
                    <Button type="button" text="Cancel" onClick={onClose} className="btn" />
                    <Button type="submit" text="Update" className="btn bg_red" disabled={loading} isLoading={loading} />
                </div>
            </form>
        </DashboardModal>
    );
}
