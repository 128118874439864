/* eslint-disable max-len */
import { NavLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import type { ReactNode } from "react";

import { useModal } from "@/hooks/useModal";
import emailIcon from "@/assets/img/mail_box.svg";
import weddingIcon from "@/assets/img/hamburger_menu.svg";
import budgetTrackerIcon from "@/assets/img/money_icon.svg";
import checklistIcon from "@/assets/img/sidebar_checklist_icon.svg";
import myEventIcon from "@/assets/img/more_icon.svg";
// import floorPlanIcon from "@/assets/img/floor_plan.svg";
import profileIcon from "@/assets/img/profile.svg";
import signOutIcon from "@/assets/img/sign_out_icon.svg";
import useAuth from "@/hooks/useAuth";
import AuditIcon from "@/assets/img/sidebar_search_icon.svg";
import { useFilter } from "@/hooks/useFilter";
import ProfileIcon from "@/assets/icon/ProfileIcon";
import chatImg from "@/assets/img/chat_black.svg";

const sidebarLinks = [
    {
        text: "Event Overview",
        link: "/dashboard",
        icon: weddingIcon,
    },
    {
        text: "Budget Tracker",
        link: "/dashboard/budget-tracker",
        icon: budgetTrackerIcon,
    },
    {
        text: "Checklist",
        link: "/dashboard/checklist",
        icon: checklistIcon,
    },
    {
        text: "Audits",
        link: "/dashboard/audits",
        icon: AuditIcon,
    },
    {
        text: "My Events",
        link: "/dashboard/my-events",
        icon: myEventIcon,
    },
    {
        text: "Profile",
        link: "/dashboard/edit-profile",
        icon: profileIcon,
    },
];

interface Props {
	links: Array<{ text: string; link: string; icon: string }>;
}

function SidebarLink({ links }: Props) {
    const { setCeremony } = useFilter();
    const queryClient = useQueryClient();
    const {setModal} = useModal();

    function showVendorAssistanceModal() {
        setModal("vendor_assistance_modal");
    }

    function onLinkNavigate(sidebarText: string) {
        if (sidebarText === "Audits") {
            queryClient.resetQueries();
        }

        setCeremony({
            name: "All Ceremonies",
            id: "all",
        });
    }

    return (
        <ul className="sidebar_links">
            {links.map((sidebarItem) => {
                return (
                    <li key={sidebarItem.text}>
                        <NavLink
                            to={sidebarItem.link}
                            className={({ isActive }) => (isActive ? "nav_link active align-items-center" : "nav_link align-items-center")}
                            onClick={() => onLinkNavigate(sidebarItem.text)}
                            end>
                            <img src={sidebarItem.icon} alt={`${sidebarItem.text} link`} />
                            {sidebarItem.text}
                        </NavLink>
                    </li>
                );
            })}
            <li>
                <NavLink
                    to="/dashboard/edit-profile"
                    className={({ isActive }) => (isActive ? "nav_link active align-items-center" : "nav_link align-items-center")}
                    end>
                    <ProfileIcon />
					Profile
                </NavLink>
                <button
                    className="nav_link btn mt-3 align-items-center"
                    onClick={showVendorAssistanceModal}>
                    <img src={chatImg} alt="Vendor assistance" />
					Vendor Assistance
                </button>
            </li>
        </ul>
    );
}

interface SidebarButtonProps {
	text: string;
	icon: string;
	onClick?: () => void;
}

function SidebarButton({ text, icon, onClick }: SidebarButtonProps) {
    const alt = `${text} link`;
    return (
        <button className="dashboard_button px-0" onClick={onClick}>
            <img src={icon} alt={alt} />
            {text}
        </button>
    );
}

type SidebarExternalLinkType = SidebarButtonProps & {
	link: string;
};

function SidebarExternalLink({ text, link, icon }: SidebarExternalLinkType) {
    const alt = `${text} link`;
    return (
        <a href={link} className="dashboard_button" target="_blank">
            <img src={icon} alt={alt} />
            {text}
        </a>
    );
}

interface DashboardSidebarProps {
	children: ReactNode;
}

export default function DashboardSidebar({ children }: DashboardSidebarProps) {
    const { signUserOut } = useAuth();
    return (
        <aside className="pt-5 bg-white">
            {children}
            <SidebarLink links={sidebarLinks} />
            <div className="divider" />
            <div className="cta">
                <SidebarExternalLink link="https://mail.google.com/mail/?view=cm&fs=1&to=help@planaday.events" text="Support" icon={emailIcon} />
                <SidebarButton text="Sign Out" icon={signOutIcon} onClick={signUserOut} />
            </div>
        </aside>
    );
}
