import { toast } from "react-toastify";
import { useState } from "react";

import SiteModal from "@/components/modals/SiteModal";
import logo from "@/assets/img/planaday_logo_small.png";
import UserIcon from "@/assets/icon/UserIcon";
import GroupUserListIcon from "@/assets/icon/GroupUserListIcon";
import SelectOption from "@/components/SelectOption";
import Plannerform from "@/components/form/Plannerform";
import { UserList } from "@/types";
import { updateUserDetails } from "@/axios/put-request";
import Spinner from "@/components/Spinner";
import "@/styles/welcome_modal.scss";

interface Props {
	show: boolean;
	name: string;
	userId: string;
	onHide: () => void;
}

/**
 * SelectUserTypeModal
 * @param show
 * @param name
 * @param userId
 * @param onHide
 * @returns JSX
 */

export default function SelectUserTypeModal({ show, name, userId, onHide }: Props) {
    const [isAPlanner, setIsAPlanner] = useState(false);
    const [loading, setLoading] = useState(false);

    function userIsAPlanner() {
        setIsAPlanner(true);
    }

    async function updateUserTypeAsCelebrant() {
        try {
            setLoading(true);
            const updateUserRequest = await updateUserDetails(userId, {
                user_type: UserList.celebrant,
            });
            if (!updateUserRequest.data.success) {
                toast.error(updateUserRequest.data.message);
                return;
            }
            setLoading(false);
            toast.success(updateUserRequest.data.message);
            onHide();
            return;
        } catch (error: unknown) {
            const _error = error as { data: { message: string } };
            setLoading(false);
            toast.error("Oops an error occured" + _error?.data?.message);
        }
    }

    return (
        <SiteModal className="welcome_modal select_user_type_modal" backdropClassName="welcome_modal_backdrop" show={show} onHide={onHide}>
            <section className="welcome_modal_content">
                <div className="wrapper">
                    <img src={logo} alt="welcome image" />
                </div>

                {isAPlanner ? (
                    <Plannerform name={name} userId={userId} onHide={onHide} />
                ) : (
                    <div className="text_content">
                        <h4>Welcome to the New Planaday Experience!</h4>
                        <p className="text_1">
							We're excited to introduce our redesigned interface to better serve your needs. Please select your user type to continue:
                        </p>

                        <div className="select_user_type">
                            <SelectOption
                                onClick={userIsAPlanner}
                                icon={<GroupUserListIcon />}
                                title="Event Planner"
                                text="Multiple events to plan? This is for you"
                            />
                            <div className="position-relative">
                                <SelectOption
                                    onClick={updateUserTypeAsCelebrant}
                                    icon={<UserIcon />}
                                    title="Celebrant"
                                    text="Planning an event, need organisation?"
                                />
                                {loading && <Spinner size={30} className="spinner position-absolute" />}
                            </div>
                        </div>
                    </div>
                )}
            </section>
        </SiteModal>
    );
}
