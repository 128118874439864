import { useNavigate } from "react-router-dom";
import { PropsWithChildren } from "react";

import image from "@/assets/img/default_profile_img.webp";
import LoaderIcon from "@/assets/icon/LoaderIcon";
import type { todoType } from "@/types";

interface Props {
	checklist: todoType;
}
export default function ManageChecklist({ checklist, children }: PropsWithChildren<Props>) {
    const today = new Date();
    const todo = checklist?.filter((item) => !item.isDone && item.due && new Date(item.due) >= today);
    const navigate = useNavigate();

    return (
        <div className="checklist_view item_view">
            <div className="row">
                <h4>
                    <LoaderIcon /> To-Do
                </h4>
                <button onClick={() => navigate("/new/dashboard/checklist")}>Manage</button>
            </div>
            <p>Upcoming tasks</p>
            <div className="item_group">
                {todo?.length === 0 && (
                    <div className="default_checklist_item">
                        <time>
							--
                            <br />
                            <br />
                            <br />
                            <span>--</span>
                        </time>
                        <div className="item">
                            <img src={image} alt="vendor image" />
                            <div className="text">
                                <h5>--</h5>
                                <time>--</time>
                            </div>
                        </div>
                    </div>
                )}
                {children}
            </div>
        </div>
    );
}
