import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { usePaystackPayment } from "react-paystack";
import { addMonths } from "date-fns";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import { getUserDetails } from "@/axios/get-request";
import { CurrencyList } from "@/types";
import { subscribeToPlan } from "@/axios/post-request";
import payStackConfig from "@/payStackConfig";
import { initializePaymentType, Pricing } from "@/data/types";
import CheckIcon2 from "@/assets/icon/CheckIcon2";
import "@/styles/settings.scss";

const menu = [
    { text: "Profile", link: "/new/dashboard/settings" },
    { text: "Account", link: "/new/dashboard/settings/account", active: true },
    { text: "Vendors", link: "/new/dashboard/vendors" },
];

type countryDetailsType = {
	currency: `${CurrencyList}` | "";
	country: string;
};

export default function AccountPage() {
    const [countryDetails, setCountryDetails] = useState<countryDetailsType>({
        country: "",
        currency: "",
    });
    const { data, status } = useQuery({
        queryKey: ["get_user"],
        queryFn: () => {
            return getUserDetails();
        },
    });
    const startDate = addMonths(new Date(), 1);

    const subscriptionConfig = {
        metadata: {
            custom_fields: [
                {
                    display_name: "description",
                    variable_name: "description",
                    value: "You'll be charged the sum of N50.00 to check if your account is valid, this will be refunded",
                },
            ],
        },
    };

    const config = payStackConfig(data?.result?.email as string, Pricing.Test_account_validity, subscriptionConfig);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        await subscribeToPlan({
            email: data?.result?.email as string,
            startDate: startDate.toISOString(),
        });
    }

    useEffect(() => {
        setCountryDetails({
            ...countryDetails,
            country: data?.result?.country ?? "",
            currency: data?.result?.currency ?? "",
        });
    }, [status]);

    const accountStatus = data?.result?.subscription_details ? "Free trial" : "Inactive";
    const accountStatusClassname = data?.result?.subscription_details ? "free_trial" : "inactive";

    return (
        <NewDashboardLayout title="Settings" description="Manage your profile, workspace and more.">
            <section className="settings_page">
                <aside>
                    <ul>
                        {menu.map((item, index) => {
                            const className = item?.active ? "active" : "";
                            return (
                                <li key={index} className={className}>
                                    <Link to={item.link}>{item.text}</Link>
                                </li>
                            );
                        })}
                    </ul>
                </aside>
                <div className="settings_view">
                    <h4>Subscription</h4>
                    <div className="subscription_benefit">
                        <h4>Subscribe to Planaday Pro</h4>
                        <ul>
                            <li>
                                <CheckIcon2 fill="#000" /> Budget Tracker
                            </li>
                            <li>
                                <CheckIcon2 fill="#000" /> Checklist
                            </li>
                            <li>
                                <CheckIcon2 fill="#000" /> Event Timeline
                            </li>
                            <li>
                                <CheckIcon2 fill="#000" /> Floor Plan
                            </li>
                            <li>
                                <CheckIcon2 fill="#000" /> New Features e.g Event Day Flow
                            </li>
                        </ul>
                        <h6>
                            <span>₦15,000</span> / month
                        </h6>
                    </div>
                    <div className="profile_row">
                        <div className="text_content">
                            <p>Status</p>
                            <h6>
								Planday Pro - Monthly <sup className={accountStatusClassname}>{accountStatus}</sup>
                            </h6>
                        </div>
                        {!data?.result?.subscription_details && <button onClick={makePaymentHandler}>Subscribe</button>}
                    </div>
                </div>
            </section>
        </NewDashboardLayout>
    );
}
