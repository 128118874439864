import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ChangeEvent, useState } from "react";
import { Dropdown } from "react-bootstrap";

import CalendarIcon from "@/assets/icon/CalendarIcon";
import PlusIcon from "@/assets/icon/PlusIcon";
import Input2 from "@/components/form/Input2";
import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import SendIcon from "@/assets/icon/SendIcon";
import SiteDropdown from "@/components/Dropdown";
import AddUserRoundIcon from "@/assets/icon/AddUserRoundIcon";
import useCollaboratorInvite from "@/hooks/useCollaboratorInvite";
import { getEvents } from "@/axios/get-request";
import { useEvent } from "@/hooks/useEvent";
import "@/styles/onboarding_stages.scss";
import "@/styles/auth_page.scss";
import Loader from "@/components/loader";

type collaboratorType = Array<{
    name: string;
    email: string;
    role: string;
    permission: string;
}>;

const collaboratorInputDetails = { name: "name", label: "Name", placeholder: "Collaborator's name" };
const roleOptions = ["Groom 🤵🏻‍♂️", "Bride 👰", "Planner 👩🏻‍💻", "Lead planner 🕵️‍♂️", "Coordinator 👷‍♀️", "Relative 👫🏻", "Other 🧐"];
const collaboratorPermission = [
    { name: "can_edit", label: "Can Edit" },
    { name: "view_only", label: "View Only" },
];
export default function OnboardingEventPlannerStage2() {
    const navigate = useNavigate();
    const { selectedEventId } = useEvent();
    const [showAddCollaborator, setShowAddCollaborator] = useState(false);
    const { inviteCollaboratorRequest, loading } = useCollaboratorInvite();
    const [hasInvitedCollaborator, setHasInvitedCollaborator] = useState(false);
    const [collaborators, setCollaborators] = useState<collaboratorType>([]);
    const [collaborator, setCollaborator] = useState({
        name: "",
        email: "",
        role: "Planner",
        permission: "can_edit",
    });
    const { status, data, refetch } = useQuery({
        queryKey: [`get_event_${selectedEventId}`],
        queryFn: () => getEvents(),
        onSuccess: (data) => {
            if (data?.result && data?.result?.length > 0) {
                window.localStorage.setItem("PLANNER_COLLABORATOR_INVITED", "TRUE");
                setHasInvitedCollaborator(true);
            }
        },
        enabled: hasInvitedCollaborator,
    });

    function updateCollaboratorDetails(event: ChangeEvent<HTMLInputElement>) {
        setCollaborator({
            ...collaborator,
            [event.target.name]: event.target.value,
        });
    }

    function showAddCollaboratorDropdown() {
        setShowAddCollaborator(true);
    }

    function nextStageHandler() {
        return navigate(`/new/planner/pricing`);
    }

    function onSelectCollaboratorHandler(value: string, name: string) {
        setCollaborator({
            ...collaborator,
            [name]: value,
        });
    }

    const activeEvent =
        status === "success" && data?.result
            ? selectedEventId
                ? data?.result?.filter((event) => event.id === selectedEventId)
                : data?.result
            : null;

    const eventCollaborators = activeEvent && Array.isArray(activeEvent) && activeEvent.length > 0 ? activeEvent[0]?.collaborators : [];

    const selectedCollaboratorPermission = collaboratorPermission.filter((item) => item.name === collaborator.permission);

    async function onInviteCollaboratorRequestHandler() {
        await inviteCollaboratorRequest(collaborator);
        setCollaborators([...collaborators, collaborator]);
        window.localStorage.setItem("PLANNER_COLLABORATOR_INVITED", "TRUE");
        setHasInvitedCollaborator(true);
        refetch();
    }

    const loadingClassName = loading ? "loading" : "";

    return (
        <NewOnboardingLayout className="onboarding_page_stage" text="Unlock your dream event in a few steps...">
            <div className="content d-flex flex-column auth">
                <div className="auth_content onboarding_stage">
                    <h4 className="mb-0">Invite Collaborator 📧</h4>
                    <form className="invite_collaborator_form">
                        <div className="collaborators_section form_row">
                            <div className="section_title">
                                <div>
                                    <CalendarIcon />
                                    <h6>Collaborators</h6>
                                </div>
                                {eventCollaborators.length <= 2 && (
                                    <button type="button" onClick={showAddCollaboratorDropdown}>
                                        Invite <PlusIcon />
                                    </button>
                                )}
                            </div>
                            {showAddCollaborator && (
                                <section className="add_collaborators">
                                    <div className="input_group">
                                        <Input2 value={collaborator.name} input={collaboratorInputDetails} onChange={updateCollaboratorDetails} />
                                        <SiteDropdown className="custom" title={collaborator.role}>
                                            {roleOptions.map((role, index) => (
                                                <Dropdown.Item key={index} type="button" onClick={() => onSelectCollaboratorHandler(role, "role")}>
                                                    {role}
                                                </Dropdown.Item>
                                            ))}
                                        </SiteDropdown>
                                    </div>
                                    <div className="top_row">
                                        <div className="custom_input">
                                            <AddUserRoundIcon />
                                            <input
                                                className="mb-0"
                                                type="email"
                                                name="email"
                                                value={collaborator.email}
                                                onChange={updateCollaboratorDetails}
                                                placeholder="Enter email to invite"
                                            />
                                        </div>
                                        <div className="button_group">
                                            <SiteDropdown className="custom permission" title={selectedCollaboratorPermission[0].label}>
                                                {collaboratorPermission.map((permission, index) => (
                                                    <Dropdown.Item
                                                        key={index}
                                                        type="button"
                                                        onClick={() => onSelectCollaboratorHandler(permission.name, "permission")}>
                                                        {permission.label}
                                                    </Dropdown.Item>
                                                ))}
                                            </SiteDropdown>
                                            <button
                                                type="button"
                                                className={`invite_btn ${loadingClassName}`}
                                                onClick={onInviteCollaboratorRequestHandler}>
                                                {loading ? (
                                                    <span>
                                                        <Loader />
                                                    </span>
                                                ) : (
                                                    <SendIcon />
                                                )}{" "}
                                            </button>
                                        </div>
                                    </div>
                                </section>
                            )}
                        </div>
                    </form>
                </div>
                <div className="button_group">
                    <button className="prev_btn">Previous</button>
                    <button className="next_btn" onClick={nextStageHandler}>
                        Next
                    </button>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
