/* eslint-disable max-len */
import { PropsWithChildren } from "react";
import { NavLink, Link } from "react-router-dom";

import HomeIcon from "@/assets/icon/HomeIcon";
import DashboardIcon from "@/assets/icon/DashboardIcon";
import BudgettrackerIcon from "@/assets/icon/BudgettrackerIcon";
import ChecklistIcon from "@/assets/icon/ChecklistIcon";
// import HeadphoneIcon from "@/assets/icon/HeadphoneIcon";
import AuditIcon from "@/assets/icon/AuditIcon";
import logo from "@/assets/img/logo_full.png";
import smallLogo from "@/assets/img/planaday_logo_small.png";
import LeftDrawerIcon from "@/assets/icon/LeftDrawerIcon";
// import FolderIcon from "@/assets/icon/FolderIcon";
// import TentIcon from "@/assets/icon/TentIcon";
import useFloorPlan from "@/hooks/useFloorPlan";
import SettingsCogIcon from "@/assets/icon/SettingsCogIcon";
import SquareDashedIcon from "@/assets/icon/SquareDashedIcon";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import CancelIcon from "@/assets/icon/Cancel";
import { useEvent } from "@/hooks/useEvent";

const sidebarLinks = {
    overview: {
        text: "Home",
        link: "/new/dashboard",
        icon: <HomeIcon />,
    },
    main: [
        {
            text: "Overview",
            link: "/new/dashboard/event-overview",
            icon: <DashboardIcon />,
        },
        {
            text: "Budget Tracker",
            link: "/new/dashboard/budget-tracker",
            icon: <BudgettrackerIcon />,
        },
        {
            text: "Checklist",
            link: "/new/dashboard/checklist",
            icon: <ChecklistIcon />,
        },
        {
            text: "Activity Log",
            link: "/new/dashboard/activity-log",
            icon: <AuditIcon />,
        },
    ],
    floorPan: {
        text: "Floor Plan",
        link: "/new/dashboard/floor-plan",
        icon: <SquareDashedIcon />,
        isBeta: true,
    },
    more: [
        // {
        //     text: "Documents",
        //     link: "/new/dashboard/documents",
        //     icon: <FolderIcon />,
        // },
        // {
        //     text: "Support",
        //     link: "/new/dashboard/support",
        //     icon: <HeadphoneIcon className="m-0" />,
        // },
        // {
        //     text: "Community",
        //     link: "/new/dashboard/community",
        //     icon: <TentIcon />,
        // },
        {
            text: "Settings",
            link: "/new/dashboard/settings",
            icon: <SettingsCogIcon />,
        },
    ],
};

type sidebarLinkType = { text: string; link: string; icon: JSX.Element; isBeta?: boolean };

interface SidebarLinkItemProps {
    sidebarItem: sidebarLinkType;
    showMenuLink: boolean;
    onClick: (value: string) => void;
}
function SidebarLinkItem({ sidebarItem, showMenuLink, onClick }: SidebarLinkItemProps) {
    const linkProps = sidebarItem.text === "Floor plan" ? { end: false } : { end: true };
    return (
        <NavLink
            {...linkProps}
            to={sidebarItem.link}
            className={({ isActive }) => (isActive ? "nav_link active align-items-center" : "nav_link align-items-center")}
            onClick={() => onClick(sidebarItem.text)}>
            <div className="sidebar_icon">{sidebarItem.icon}</div>
            {showMenuLink && sidebarItem.text}
            {showMenuLink && sidebarItem?.isBeta && <span className="beta">Beta</span>}
        </NavLink>
    );
}

interface Props {
    links: Array<sidebarLinkType>;
    title: string;
    className?: string;
    showMenuLink: boolean;
    onClick: (value: string) => void;
}

function SidebarLink({ links, showMenuLink, title, onClick, className = "", children }: PropsWithChildren<Props>) {
    return (
        <div className={`sidebar_section ${className}`}>
            <h6>{title}</h6>
            <div className="sidebar_links">
                {links.map((sidebarItem) => {
                    return <SidebarLinkItem onClick={onClick} showMenuLink={showMenuLink} sidebarItem={sidebarItem} key={sidebarItem.text} />;
                })}
                {children}
            </div>
        </div>
    );
}

interface NewDashboardSidebarProps {
    children: JSX.Element;
    showFloorPlan: boolean;
    updateBudgetCategory: () => void;
}

export default function NewDashboardSidebar({ children, showFloorPlan, updateBudgetCategory }: PropsWithChildren<NewDashboardSidebarProps>) {
    const { sidebar, toggleSidebar } = useFloorPlan();
    const mobile = useMediaQuery("(max-width: 768px)");
    const { setActiveCeremonyId } = useEvent();

    const className = sidebar ? "full_sidebar" : "icon_sidebar";
    const logoUrl = sidebar ? logo : smallLogo;
    const siteLogo = mobile ? smallLogo : logoUrl;
    const sidebarClassName = mobile ? "mobile_view" : "";

    function onClickLinkHandler(text: string) {
        if (text === "Budget Tracker") {
            setActiveCeremonyId("all");
            updateBudgetCategory();
        }
        if (mobile) {
            toggleSidebar();
        }
    }

    return (
        <aside className={`dashboard_sidebar ${sidebarClassName} ${className}`}>
            <div className="sidebar_top">
                <Link to="/new/dashboard">
                    <img src={siteLogo} alt="Planaday logo" />
                </Link>
                <button className="drawerBtn" onClick={toggleSidebar}>
                    {mobile ? <CancelIcon fill="#404040" /> : <LeftDrawerIcon />}
                </button>
            </div>
            <div className="sidebar_link_group">
                <div>
                    <div className="overview sidebar_section">
                        <SidebarLinkItem showMenuLink={sidebar} onClick={onClickLinkHandler} sidebarItem={sidebarLinks.overview} />
                    </div>
                    <SidebarLink title="APPS" onClick={onClickLinkHandler} showMenuLink={sidebar} links={sidebarLinks.main}>
                        {showFloorPlan && <SidebarLinkItem onClick={onClickLinkHandler} showMenuLink={sidebar} sidebarItem={sidebarLinks.floorPan} />}
                    </SidebarLink>
                </div>
                <div className="more_links">
                    <SidebarLink
                        onClick={onClickLinkHandler}
                        title="MORE"
                        showMenuLink={sidebar}
                        className="more_section"
                        links={sidebarLinks.more}
                    />
                    {children}
                </div>
            </div>
        </aside>
    );
}
