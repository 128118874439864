import { Link, useSearchParams } from "react-router-dom";
import { useState, type PropsWithChildren } from "react";
import { Helmet } from "react-helmet";

import logo from "@/assets/img/logo.png";
import google from "@/assets/img/google.svg";
import useAuth from "@/hooks/useAuth";
import ForgotPasswordModal from "@/components/modals/ForgotPasswordModal";
import "@/styles/auth-layout.scss";

interface Props {
	type: "login" | "signup" | "reset_password";
}

export default function AuthLayout({ type, children }: PropsWithChildren<Props>) {
    const { googleAuth } = useAuth();
    const [modal, setModal] = useState(false);
    const [searchParams] = useSearchParams();
    const invitee_role = searchParams.get("role");
    const inviter_email = searchParams.get("inviter_email");
    const inviter_id = searchParams.get("id");
    const event_id = searchParams.get("event_id");

    const title = type === "login" ? "Log in" : type === "signup" ? "Sign up" : "Reset Password";
    const text = type === "login" ? "Log in with Google" : "Sign up with Google";
    const formNote = type === "login" ? "Don’t have an account?" : "Already have an account?";
    const linkText = type === "login" ? "Sign up" : "Log in";
    const link = type === "login" ? "signup" : "login";

    function closeModal() {
        setModal(false);
    }

    async function googleAuthHandler() {
        await googleAuth({
            inviter_id,
            invitee_role,
            inviter_email,
            event_id,
        });
    }

    return (
        <>
            <Helmet>
                <title>Planaday | {type}</title>
                <meta
                    name="description"
                    content="Use Planaday to determine how much you will spend on your dream wedding"
                />
            </Helmet>
            <ForgotPasswordModal show={modal} onHide={closeModal} />
            <section className="d-flex flex-lg-row flex-column auth_layout">
                <div className="auth_left_column col-lg-5 col-12">
                    <img src={logo} className="logo" alt="Planaday logo" />
                    <h1 title="Start planning your dream event today">Start planning your dream event today.</h1>
                </div>
                <div className="auth_right_column col-lg-7 col-12">
                    <h4>{title}</h4>
                    <p>
                        {formNote} <Link to={`/${link}`}>{linkText}</Link>
                    </p>
                    {children}
                    {type !== "reset_password" && (
                        <div className="other_auth_method">
                            <div className="or_socials">
                                <div>
                                    <hr />
                                    <span>OR</span>
                                </div>
                            </div>
                            <div className="text d-flex align-items-center mx-auto">
                                <p>{text}</p>
                            </div>
                            <div className="icons">
                                {/* <button onClick={facebookAuth}>
                                    <img className="facebook" src={facebook} alt="facebook icon" />
                                </button> */}
                                <button onClick={googleAuthHandler}>
                                    <img src={google} className="google" alt="google icon" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </>
    );
}
