/* eslint-disable max-len */

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useState } from "react";
import inviteCollaboratorJSON from "@/json/invite_collaborator.json";

import SiteModal from "@/components/modals/SiteModal";
import Button from "@/components/Button";
import { inviteCollaborator } from "@/axios/put-request";
import { getUID } from "@/axios/config";
import useCollaborator from "@/hooks/useCollaborator";
import CollaboratorInputRow from "@/components/form/CollaboratorInputRow";
import { useEvent } from "@/hooks/useEvent";
import PricingTable from "@/components/PricingTable";
import { CollaboratorPaymentPlan, PaymentType, eventType, pricingPlanType } from "@/types";
import PricingPayment from "@/components/PricingPayment";
import useAudit from "@/hooks/useAudit";
import { validateEmail } from "@/helper";

interface Props {
    show: string | null;
    onHide: () => void;
    invitee: {
        name: string;
        email: string;
        role: string;
    };
    collaboratorsEmails: string[];
    activeCollaboratorCount: number | null;
    canAddNewCollaborator: boolean;
    collaboratorPaymentPlanStatus: keyof typeof CollaboratorPaymentPlan;
}

export default function AddCollaboratorModal({
    show,
    onHide,
    invitee,
    collaboratorsEmails,
    activeCollaboratorCount,
    canAddNewCollaborator,
    collaboratorPaymentPlanStatus,
}: Props) {
    const queryClient = useQueryClient();
    const { selectedEventId } = useEvent();
    const { collaborator1, collaborator2, onChangeHandler1, onChangeHandler2 } = useCollaborator(collaboratorPaymentPlanStatus);
    const [selectPricingPlan, setPricingPlan] = useState<pricingPlanType>("");
    const { updateAuditHandler } = useAudit();

    function choosePricingPlan(plan: pricingPlanType) {
        setPricingPlan(plan);
    }

    function resetPricingPlan() {
        setPricingPlan("");
    }

    const collaborators =
        collaborator1.email && collaborator1.role && collaborator2.email && collaborator2.role && activeCollaboratorCount === 0
            ? [collaborator1, collaborator2]
            : collaborator1.email && collaborator1.role
                ? [collaborator1]
                : collaborator2.email && collaborator2.role
                    ? [collaborator2]
                    : [];

    async function inviteEventCollaboratorHandler() {
        const id = getUID();
        if (collaborators.length > 0) {
            const { name, email, role } = invitee;
            return await inviteCollaborator(id, {
                eventId: selectedEventId,
                collaborators,
                inviteeEmail: email,
                inviteeName: name,
                inviteeRole: role,
            });
        }
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["invite_partner"],
        mutationFn: () => inviteEventCollaboratorHandler(),
        onSuccess: (data) => {
            toast.success(data?.data?.message);
            collaborators.map((collaborator) => {
                if (collaborator.email) {
                    updateAuditHandler("general", "invite_collaborator", collaborator.email, null);
                }
            });
            queryClient.resetQueries({ queryKey: [`get_event_${selectedEventId}`] });
            onHide();
        },
        onError: (error: unknown) => {
            const _error = error ?? "an error occured";
            const errorText = _error as string;
            return toast.error(errorText);
        },
    });

    function inviteCollaboratorHandler() {
        if (!collaborator1.email) {
            return toast.error("Add at least one collaborator");
        }
        if (collaborator1.email && !validateEmail(collaborator1.email)) {
            return toast.error("Invalid email address");
        }
        if (collaborator2.email && !validateEmail(collaborator2.email)) {
            return toast.error("Invalid email address");
        }
        if (collaborator1.email && !collaborator1.role) {
            return toast.error("role is required");
        }
        if (collaborator2.email && !collaborator2.role) {
            return toast.error("role is required");
        }
        if (collaborator1.email === collaborator2.email) {
            return toast.error("You can't invite same collaborator twice!");
        }
        if (invitee.email === collaborator1.email || invitee.email === collaborator2.email) {
            return toast.error("You can't invite yourself as a collaborator");
        }
        if (collaboratorsEmails.includes(collaborator1.email) || collaboratorsEmails.includes(collaborator2.email)) {
            return toast.error("You can't invite an existing collaborator");
        }
        mutate();
    }

    const showModal = show === "collaborator_modal" ? true : false;
    const modalClassname = selectPricingPlan ? "" : canAddNewCollaborator ? "" : "medium";

    const modalTitle = canAddNewCollaborator ? "Invite Collaborator" : selectPricingPlan ? `${selectPricingPlan} Pricing` : "Pricing Plan";

    return (
        <SiteModal title={modalTitle} show={showModal} onHide={onHide} className={modalClassname}>
            <>
                {canAddNewCollaborator ? (
                    <section>
                        <p>Let the planning begin! Share an invitation link with your collaborator and let them plan, track and execute with you. </p>
                        <form>
                            <div className="collaborators mt-4">
                                {inviteCollaboratorJSON.map((item, index) => {
                                    const _collaborator = index === 0 ? collaborator1 : collaborator2;
                                    const collaborator = _collaborator as eventType["collaborators"][0];
                                    const onChangeHandler = index === 0 ? onChangeHandler1 : onChangeHandler2;
                                    return (
                                        canAddNewCollaborator &&
                                        index === 0 && (
                                            <CollaboratorInputRow
                                                key={index}
                                                showLabel={false}
                                                index={index}
                                                item={item}
                                                collaborator={collaborator}
                                                onChangeHandler={onChangeHandler}
                                            />
                                        )
                                    );
                                })}
                            </div>
                            <div className="d-flex justify-content-end">
                                <Button
                                    text="Invite"
                                    className="bg_red text-white mt-5 font-weight-bold mb-3"
                                    isLoading={isLoading}
                                    onClick={inviteCollaboratorHandler}
                                />
                            </div>
                        </form>
                    </section>
                ) : (
                    <PricingTable type="collaborator" selectPricingPlan={selectPricingPlan} choosePricingPlan={choosePricingPlan}>
                        <PricingPayment
                            title={selectPricingPlan}
                            onCloseModal={onHide}
                            paymentType={PaymentType.COLLABORATOR}
                            resetPricingPlan={resetPricingPlan}
                        />
                    </PricingTable>
                )}
            </>
        </SiteModal>
    );
}
