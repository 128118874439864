import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import { useQueryClient, useQuery } from "@tanstack/react-query";

import { addDefaultBudget } from "@/axios/post-request";
import { getRandomQuoteIndex } from "@/utils/getRandomQuote";
import { formatOnboardingCeremony } from "@/helper";
import { useEvent } from "@/hooks/useEvent";
import { updateCeremony, updateOnboardingDetails } from "@/axios/put-request";
import { getUID } from "@/axios/config";
import { getOnboardingDetails } from "@/axios/get-request";
import type { ceremonyLocationDataType, ceremonyType } from "@/types";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

export default function useOnboarding() {
    const user_id = getUID();
    const navigate = useNavigate();
    const {data} = useQuery({
        queryKey: ["onboarding_details"],
        queryFn: () => getOnboardingDetails(),
        refetchOnMount: true
    });
    const [loading, setLoading] = useState(false);
    const { selectedEventId } = useEvent();
    const queryClient = useQueryClient();


    async function addDefaultBudgetRequest() {
        await addDefaultBudget([{ id: 1, location: "Lagos", no_of_guests: 400 }], selectedEventId);
    }

    async function saveToProfileHandler() {
        try {
            setLoading(true);
            const calculatorDetails = data?.result?.calculator_details ? data?.result?.calculator_details : null;

            await updateOnboardingDetails(user_id, {
                completed: true,
            });
            const ceremonyLocationData = calculatorDetails?.data as ceremonyLocationDataType;
            const existingCeremonyDetailsStorage = window.localStorage.getItem(LOCAL_STORAGE_KEYS.ceremonies);
            const existingCeremonyDetails: ceremonyType | null = existingCeremonyDetailsStorage ? JSON.parse(existingCeremonyDetailsStorage) : null;
            const ceremonies =
            existingCeremonyDetails ? formatOnboardingCeremony(existingCeremonyDetails, ceremonyLocationData) : null;

            const skip_budget_breakdown = window.localStorage.getItem(LOCAL_STORAGE_KEYS.skip_event_budget);

            if (skip_budget_breakdown) {
                await addDefaultBudgetRequest();
            } else if (ceremonies && ceremonies.length > 0 && !skip_budget_breakdown) {
                const updateCeremoniesRequest = ceremonies.map((ceremonyItem) => {
                    updateCeremony(ceremonyItem, ceremonyItem.id, selectedEventId);
                });
                await Promise.all(updateCeremoniesRequest);
            }

            setLoading(false);
            getRandomQuoteIndex();
            window.localStorage.clear();
            navigate("/dashboard/events");
            queryClient.invalidateQueries({ queryKey: [`get_event_${selectedEventId}`], exact: true });
        } catch (error) {
            setLoading(false);
            console.log("error", error);
            toast.error("oops, unable to onboard user");
        }
    }


    return {
        saveToProfileHandler,
        loading,
    };
}
