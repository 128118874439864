import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import WalletIcon from "@/assets/icon/WalletIcon";
import { deleteCeremonyRequest } from "@/axios/delete-request";
import DashboardModal from "@/components/modals/DashboardModal";
import Spinner from "@/components/Spinner";
import { useEvent } from "@/hooks/useEvent";

interface Props {
	onClose: () => void;
	activeEventId: string;
	ceremonyName: string;
	selectedCeremonyId: string;
}

export default function DeleteCeremonyModal({ onClose, ceremonyName, activeEventId, selectedCeremonyId }: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();
    const { activeCeremonyId } = useEvent();

    async function deleteCeremonyHandler() {
        try {
            if (!activeEventId) {
                return toast.error("Please select an event");
            }
            if (!selectedCeremonyId) {
                return toast.error("Please select a ceremony");
            }
            setLoading(true);
            const deleteCeremonyResponse = await deleteCeremonyRequest(selectedCeremonyId, activeEventId);
            setLoading(false);
            if (!deleteCeremonyResponse.data.success) {
                return toast.error(deleteCeremonyResponse.data.message ?? "Oops unable to delete category");
            }
            queryClient.resetQueries({ queryKey: [`get_budget_categories_${activeEventId}_${activeCeremonyId}`] });
            queryClient.resetQueries({ queryKey: [`get_event_${activeEventId}`] });
            onClose();
            return toast.success("Ceremony deleted");
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    return (
        <DashboardModal showHeader={false} className="small_width delete_category_cost_item_modal" onClose={onClose}>
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <WalletIcon />
                </div>
                <h4>Are you sure you want to delete {ceremonyName}&#39;s ceremony?</h4>

                <button onClick={deleteCeremonyHandler}>
					Delete Ceremony
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
