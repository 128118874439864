import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import leftArrow from "@/assets/img/left_polygon_arrow.svg";
import rightArrow from "@/assets/img/right_polygon_arrow.svg";

import Button from "@/components/Button";
import CollaboratorInputRow from "@/components/form/CollaboratorInputRow";
import inviteCollaboratorJSON from "@/json/invite_collaborator.json";
import useCollaborator from "@/hooks/useCollaborator";
import { CollaboratorPaymentPlan, eventType } from "@/types";
import { getOnboardingDetails } from "@/axios/get-request";
import { LOCAL_STORAGE_KEYS } from "@/utils/constant";

export default function OnboardingStage3() {
    const navigate = useNavigate();
    const [collaboratorDetails, setCollaboratorDetails] = useState({
        collaborator1Email: "",
        collaborator1Role: "",
        collaborator2Email: "",
        collaborator2Role: "",
    });
    const { data, status } = useQuery({
        queryKey: ["onboarding_details"],
        queryFn: () => getOnboardingDetails(),
    });
    const { collaborator1, collaborator2, onChangeHandler1, onChangeHandler2, inviteUserPartnerHandler } = useCollaborator(
        CollaboratorPaymentPlan.FREE,
        data?.result?.onboarding_details?.event_id,
        collaboratorDetails,
    );

    function updateCollaboratorDetails(name: string, value: string) {
        setCollaboratorDetails({ ...collaboratorDetails, [name]: value });
    }

    async function onNextHandler() {
        if (!collaborator1.email && !collaborator2.email) {
            return toast.error("Add at least one collaborator");
        }
        if (collaborator1.email && !collaborator1.role) {
            return toast.error("Please, select first collaborator's role");
        }
        if (collaborator2.email && !collaborator2.role) {
            return toast.error("Please, select second collaborator's role");
        }
        if (collaborator1.email === collaborator2.email) {
            return toast.error("You can't invite same collaborator twice!");
        }
        window.localStorage.setItem(LOCAL_STORAGE_KEYS.collaborator_details, JSON.stringify(collaboratorDetails));
        navigate("/onboarding/save-profile");
        if (status === "success") {
            await inviteUserPartnerHandler(data?.result?.id, data?.result?.onboarding_details?.name, data?.result?.calculator_details?.role);
        }
    }

    function onSkipHandler() {
        return navigate("/onboarding/save-profile");
    }

    function onBackHandler() {
        return navigate("/onboarding/view-event-budget");
    }
    return (
        <div className="onboarding_content">
            <div className="onboarding_stage stage_3">
                <h4 className="mb-lg-4">Invite Your Collaborator</h4>
                <p className="mb-4">
					Let the planning party begin! Share an invitation link with two collaborators and let them plan, track and execute with you.
                </p>
                <div className="collaborator">
                    {inviteCollaboratorJSON.map((item, index) => {
                        const _collaborator = index === 0 ? collaborator1 : collaborator2;
                        const collaborator = _collaborator as eventType["collaborators"][0];
                        const onChangeHandler = index === 0 ? onChangeHandler1 : onChangeHandler2;
                        return (
                            <CollaboratorInputRow
                                key={index}
                                index={index}
                                item={item}
                                collaborator={collaborator}
                                onChangeHandler={onChangeHandler}
                                updateCollaboratorDetails={updateCollaboratorDetails}
                            />
                        );
                    })}
                </div>
                <div className="contrls mt-5 d-flex align-items-center justify-content-between w-full">
                    <Button text="BACK" onClick={onBackHandler} icon={leftArrow} iconPosition="left" className="px-0 position-relative left-0" />
                    <Button text="Next" testId="submit_collaborator_details" className="bg_red" onClick={onNextHandler} />
                    <Button text="SKIP" className="px-0 position-relative end-0" icon={rightArrow} onClick={onSkipHandler} />
                </div>
            </div>
        </div>
    );
}
