import { useState } from "react";
import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";

import { inviteCollaborator } from "@/axios/put-request";
import { validateEmail } from "@/helper";
import { getUID } from "@/axios/config";
import { CollaboratorPaymentPlan, CollaboratorPlanStatus } from "@/types";
import { getOnboardingDetails } from "@/axios/get-request";
import { useEvent } from "./useEvent";

type collaboratorType = {
    name: string;
    email: string;
    role: string;
    permission: string;
};

export default function useCollaboratorInvite() {
    const [loading, setLoading] = useState(false);
    const { selectedEventId } = useEvent();
    const { data, refetch } = useQuery({
        queryKey: ["onboarding_details"],
        queryFn: () => getOnboardingDetails(),
    });
    const eventId = data?.result?.onboarding_details?.event_id ? data?.result?.onboarding_details?.event_id : selectedEventId;

    async function inviteCollaboratorRequest(collaborator: collaboratorType, onSuccessCB?: () => void) {
        try {
            if (!collaborator.name) {
                return toast.error("Collaborator's name is required");
            }
            if (!collaborator.email) {
                return toast.error("Collaborator's email is required");
            }
            if (collaborator.email && !validateEmail(collaborator.email)) {
                return toast.error("Invalid email address");
            }
            if (!collaborator.role) {
                return toast.error("Role is required");
            }
            if (!collaborator.permission) {
                return toast.error("Permission is required");
            }
            if (!eventId) {
                refetch();
            }
            setLoading(true);
            const id = getUID();
            const inviteCollaboratorRequest = await inviteCollaborator(id, {
                eventId,
                collaborators: [
                    {
                        email: collaborator.email,
                        role: collaborator.role,
                        name: collaborator.name,
                        permission: collaborator.permission,
                        planMonthDuration: 0,
                        payment_plan: CollaboratorPaymentPlan.FREE,
                        send_plan_expiration_email: false,
                        event_ids: [eventId],
                        plan_status: CollaboratorPlanStatus.ACTIVE,
                        start_plan_date: new Date().toISOString(),
                        end_plan_date: null,
                    },
                ],
                inviteeEmail: data?.result?.email ?? "",
                inviteeName: data?.result?.onboarding_details?.name ?? "",
                inviteeRole: "Admin",
            }, true);
            toast.success(inviteCollaboratorRequest?.data?.message);
            setLoading(false);
            if (onSuccessCB) {
                onSuccessCB();
            }
            return;
        } catch (error) {
            toast.error("Oops, error inviting collaborator");
            setLoading(false);
        }
    }
    return { inviteCollaboratorRequest, loading };
}
