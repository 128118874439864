import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

import DashboardModal from "@/components/modals/DashboardModal";
import Spinner from "@/components/Spinner";
import ShieldIcon from "@/assets/icon/ShieldIcon";
import { deleteEventRequest } from "@/axios/delete-request";

interface Props {
    onClose: () => void;
    filter: string;
    eventDetails: {
        id: string;
        name: string;
    };
}

export default function DeleteUserEventModal({ filter, eventDetails, onClose }: Props) {
    const [loading, setLoading] = useState(false);
    const queryClient = useQueryClient();

    async function deleteEventHandler() {
        try {
            if (!eventDetails.id) {
                return toast.error("Event id is required");
            }
            setLoading(true);
            const deleteEventResponse = await deleteEventRequest(eventDetails.id);
            setLoading(false);
            if (!deleteEventResponse.data.success) {
                return toast.error("Oops unable to delete event");
            }
            toast.success("Event deleted");
            queryClient.invalidateQueries({ queryKey: [`get_all_events_${filter}`, "get_all_events"] });
            return onClose();
        } catch (error) {
            console.log("error", error);
            setLoading(false);
        }
    }

    return (
        <DashboardModal title="Delete Event" onClose={onClose} className="small_width delete_modal_view delete_user_event_modal">
            <section className="delete_category_cost_item">
                <div className="icon_wrapper">
                    <ShieldIcon />
                </div>
                <h4>Are you sure you want to delete {eventDetails.name}&#39;s event?</h4>

                <button onClick={deleteEventHandler}>
                    Delete Event
                    {loading && <Spinner className="ml-2" size={20} />}
                </button>
            </section>
        </DashboardModal>
    );
}
