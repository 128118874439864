import { useState } from "react";
import { toast } from "react-toastify";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import TodoIcon from "@/assets/icon/TodoIcon";
import DefaultView from "@/components/DefaultView";
import LoaderIcon from "@/assets/icon/LoaderIcon";
import ChecklistView from "@/components/ChecklistView";
import FlagIcon from "@/assets/icon/FlagIcon";
import planningTimeline from "@/assets/img/planner_timeline.webp";
import eventDayFlow from "@/assets/img/event_day_flow.webp";
import { useEvent } from "@/hooks/useEvent";
import "@/styles/checklist_new.scss";

const buttonTabs = [
    { text: "To-Do", icon: <TodoIcon /> },
    { text: "Planning Timeline", icon: <FlagIcon /> },
    { text: "Event Day Flow", icon: <LoaderIcon /> },
];

export default function NewChecklistPage() {
    const [tab, setTab] = useState(0);
    const { selectedEventId } = useEvent();

    function createEventNotification() {
        if (!selectedEventId) {
            return toast.error("Please create an event");
        }
    }

    return (
        <>
            <NewDashboardLayout title="Checklist Summary" description="Manage your To-Dos and completed tasks">
                <div className="checklist_tab_button_group">
                    {buttonTabs.map((item, index) => {
                        const className = index === tab ? "active" : "";
                        return (
                            <button key={index} className={className} onClick={() => setTab(index)}>
                                {item.icon} {item.text}
                            </button>
                        );
                    })}
                </div>
                {tab === 2 ? (
                    <div className="event_day_flow coming_soon_feature">
                        <div className="shadow_wrapper"></div>
                        <img src={eventDayFlow} />
                        <DefaultView title="Event Day Flow" description="Feature coming soon" />
                    </div>
                ) : tab === 1 ? (
                    <div className="planner_timeline coming_soon_feature">
                        <div className="shadow_wrapper"></div>
                        <img src={planningTimeline} />
                        <DefaultView title="Planning Timeline" description="Feature coming soon" />
                    </div>
                ) : selectedEventId ? (
                    <div className="checklist_view">
                        <ChecklistView />
                    </div>
                ) : (
                    <DefaultView
                        title="You have no Tasks yet"
                        description="Create tasks and assign deadlines to them"
                        buttonText="Create Task"
                        onClickHandler={createEventNotification}
                    />
                )}
            </NewDashboardLayout>
        </>
    );
}
