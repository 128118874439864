import { toast } from "react-toastify";
import { Suspense, useCallback, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

import AddChecklistTaskModal from "@/components/modals/AddChecklistTaskModal";
import { useEvent } from "@/hooks/useEvent";
import { todoType } from "@/types";
import { updateUserChecklist } from "@/axios/put-request";
import Spinner from "@/components/Spinner";
import DraggableCeremonyChecklistItem from "@/components/DraggableCeremonyChecklistItem";
import EditChecklistTaskModal from "@/components/modals/EditChecklistTaskModal";
import DeleteChecklistModal from "@/components/modals/DeleteChecklistModal";
import "@/styles/checklist_new.scss";

enum ModalList {
	add_checklist_task_modal = "add_checklist_task_modal",
	edit_checklist_task_modal = "edit_checklist_task_modal",
	delete_checklist_task_modal = "delete_checklist_task_modal",
}

type checklistType = Array<{
	isDone: boolean;
	due: Date | null;
	id: string;
	name: string;
	note: string | null;
	priority?: string;
	ceremony: {
		name: string;
		id: string;
	};
}>;

interface Props {
	todoList: checklistType;
	refetchQueryHandler: () => void;
}

export default function DraggableCeremonyChecklist({ todoList, refetchQueryHandler }: Props) {
    const [modal, setModal] = useState<string | null>(null);
    const { selectedEventId, activeCeremonyId } = useEvent();
    const [loading, setLoading] = useState(false);
    const [, setTodos] = useState<checklistType>(todoList);
    // todos;
    const [selectedCeremonyChecklist, setSelectedCeremonyChecklist] = useState<checklistType[0] | null>(null);

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            setTodos((prev) =>
                update(prev, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prev[dragIndex]],
                    ],
                }),
            );
        },
        [todoList.length],
    );

    function modalActionHandler(checklist: checklistType[0], modalAction: keyof typeof ModalList) {
        setModal(modalAction);
        setSelectedCeremonyChecklist(checklist);
    }

    async function updateChecklistStatus(checklist: todoType[0]) {
        try {
            setLoading(true);
            setSelectedCeremonyChecklist(checklist);
            const updateChecklistRequest = await updateUserChecklist({ ...checklist, isDone: !checklist.isDone }, activeCeremonyId, selectedEventId);
            setLoading(false);
            if (!updateChecklistRequest.data.success) {
                return toast.error(updateChecklistRequest.data.message);
            }
            setSelectedCeremonyChecklist(null);
            return refetchQueryHandler();
        } catch (error: unknown) {
            const _error = error as { data: { message: string } };
            setLoading(false);
            return toast.error(_error?.data?.message || "Oops an error occured");
        }
    }

    function onCloseModal() {
        setModal(null);
    }

    return (
        <>
            <Suspense>
                {modal === ModalList.add_checklist_task_modal && selectedCeremonyChecklist && (
                    <AddChecklistTaskModal ceremonyName={selectedCeremonyChecklist.name} onClose={onCloseModal} />
                )}
                {modal === ModalList.edit_checklist_task_modal && selectedCeremonyChecklist && (
                    <EditChecklistTaskModal
                        checklist={[selectedCeremonyChecklist]}
                        ceremonyName={selectedCeremonyChecklist.name}
                        refetchQueryHandler={refetchQueryHandler}
                        onClose={onCloseModal}
                    />
                )}
                {modal === ModalList.delete_checklist_task_modal && selectedCeremonyChecklist && (
                    <DeleteChecklistModal
                        selectedChecklistId={selectedCeremonyChecklist.id}
                        checklistName={selectedCeremonyChecklist.name}
                        refetchQueryHandler={refetchQueryHandler}
                        onClose={onCloseModal}
                    />
                )}
            </Suspense>
            <div className="todo_body p-0">
                {todoList.length > 0 ? (
                    <DndProvider backend={HTML5Backend}>
                        {todoList.map((checklist, checklistIndex) => (
                            <DraggableCeremonyChecklistItem
                                key={checklist.id}
                                index={checklistIndex}
                                checklist={checklist}
                                modalActionHandler={(modalAction) => modalActionHandler(checklist, modalAction)}
                                moveCard={moveCard}>
                                {loading && checklist.id === selectedCeremonyChecklist?.id ? (
                                    <Spinner size={20} />
                                ) : (
                                    <input checked={checklist.isDone} onChange={() => updateChecklistStatus(checklist)} type="checkbox" />
                                )}
                            </DraggableCeremonyChecklistItem>
                        ))}
                    </DndProvider>
                ) : (
                    <p className="not_available">No available task for this ceremony</p>
                )}
            </div>
        </>
    );
}
