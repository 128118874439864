import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import type { ChangeEvent } from "react";
import { v4 as uuidv4 } from "uuid";

import SiteModal from "@/components/modals/SiteModal";
import Input from "@/components/form/Input";
import Button from "@/components/Button";
import ceremony_modal_content from "@/json/form/budget_tracker_modal.json";
import SelectDateInput from "@/components/form/SelectDateInput";
import { updateCeremony } from "@/axios/put-request";
import { useFilter } from "@/hooks/useFilter";
import { formatNumber } from "@/helper";
import { updateAudit } from "@/axios/post-request";
import useUser from "@/hooks/useUser";
import type { ceremonyType } from "@/types";

interface Props {
	show: string | null;
	onHide: () => void;
	selectedCeremony: ceremonyType[0];
}

export default function EditCeremonyModal({ show, onHide, selectedCeremony }: Props) {
    const { ceremony: event_ceremony } = ceremony_modal_content;
    const [ceremony, setCeremony] = useState<ceremonyType[0]>(selectedCeremony);
    const queryClient = useQueryClient();
    const { ceremony: filterCeremony, setCeremony: updateFilterCeremony } = useFilter();
    const { data: userData, selectedEventId } = useUser();
    function updateCeremonyHandler() {
        if (ceremony.id === filterCeremony.id) {
            updateFilterCeremony({
                name: ceremony.name,
                id: ceremony.id,
            });
        }
        return updateCeremony({ ...ceremony }, selectedCeremony.id, selectedEventId);
    }

    const { mutate, isLoading } = useMutation({
        mutationKey: ["editCeremony"],
        mutationFn: () => updateCeremonyHandler(),
        onSuccess: async (data) => {
            updateAudit(
                [{
                    id: uuidv4(),
                    name: userData?.result?.name as string,
                    user_id: userData?.result?.id as string,
                    email: userData?.result?.email as string,
                    type: "general",
                    action: "change_ceremony",
                    changes: {
                        from: {
                            name: selectedCeremony.name,
                            location: selectedCeremony?.location as string,
                            guest: selectedCeremony?.no_of_guests as number,
                            date: selectedCeremony?.date
                        },
                        to: {
                            name: ceremony.name,
                            location: ceremony?.location as string,
                            guest: ceremony?.no_of_guests as number,
                            date: ceremony?.date
                        },
                    },
                    title: ceremony.name,
                    timestamp: new Date(),
                }],
                selectedEventId,
            );
            queryClient
                .resetQueries({
                    queryKey: [`get_event_${selectedEventId}`],
                })
                .then(() => {
                    toast.success(data.data.message);
                    onHide();
                });
        },
        onError: (error: unknown) => {
            const _error = error ?? "error adding ceremony";
            const errorText = _error as string;
            toast.error(errorText);
        },
    });

    const showModal = show === "edit_ceremony_modal" ? true : false;

    function setDateHandler(date: Date) {
        setCeremony({
            ...ceremony,
            date,
        });
    }

    function inputChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        const inputValue = e.target.name === "no_of_guests" ? formatNumber(e.target.value) : e.target.value;
        setCeremony({
            ...ceremony,
            [e.target.name]: inputValue,
        });
    }

    function editCeremonyHandler() {
        if (!ceremony.name) {
            return toast.error("Ceremony name is required");
        }
        if (!ceremony.location) {
            return toast.error("Ceremony location is required");
        }
        mutate();
    }

    const guestNumber = ceremony.no_of_guests ? ceremony.no_of_guests : "";

    return (
        <SiteModal title="Edit Ceremony" show={showModal} onHide={onHide}>
            <section className="add_new_ceremony">
                <form className="mt-5 mb-3 modal_form">
                    {event_ceremony.columns.map((input) => {
                        const _inputName = input.name as keyof ceremonyType[0];
                        const name = ceremony[_inputName] as string;
                        const inputName = name ? name : "";
                        return <Input input={input} key={input.name} value={inputName} maxLength={50} onChange={inputChangeHandler} />;
                    })}
                    <div className="input_row d-flex flex-md-row flex-column align-items-center justify-content-between">
                        <SelectDateInput className="pr-md-3" label="Date" value={selectedCeremony.date} onClick={setDateHandler} />
                        <Input
                            input={event_ceremony.row}
                            className="col-md-6 pl-lg-3 px-0"
                            value={guestNumber}
                            maxLength={5}
                            onChange={inputChangeHandler}
                        />
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button text="Save" className="bg_red text-white mt-2" isLoading={isLoading} onClick={editCeremonyHandler} />
                    </div>
                </form>
            </section>
        </SiteModal>
    );
}
