import SiteModal from "@/components/modals/SiteModal";
import { Link } from "react-router-dom";
import logo from "@/assets/img/planaday_logo_small.png";

import ArrowRight from "@/assets/icon/ArrowRight";
import "@/styles/welcome_modal.scss";

interface Props {
	show: boolean;
	onClose: () => void;
}

/**
 * OverviewWelcomeModal: This modal receives user email address and sends a copy of the budget to the mail
 * @param show
 * @param onHide
 * @returns JSX
 */

export default function OverviewWelcomeModal({ show, onClose }: Props) {
    return (
        <SiteModal className="welcome_modal" backdropClassName="welcome_modal_backdrop" show={show} onHide={onClose}>
            <section className="welcome_modal_content">
                <div className="wrapper">
                    <img src={logo} alt="welcome image" />
                </div>

                <div className="text_content">
                    <h4>Welcome to Planaday ✨</h4>
                    <p className="text_1">You’re all set to plan your day!</p>
                    <div className="first_step">First steps;</div>
                    <ul>
                        <Link to="/new/dashboard/settings" onClick={onClose} className="first">
							Personalise your profile 🆔 <ArrowRight />
                        </Link>
                        <Link to="new/dashboard/event-overview" onClick={onClose}>
							Set your budget 🧾 <ArrowRight />
                        </Link>
                    </ul>
                </div>
                <div className="button_group">
                    <button className="border_up" onClick={onClose}>
						Start Planning
                    </button>
                </div>
            </section>
        </SiteModal>
    );
}
