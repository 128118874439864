import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, Suspense } from "react";
import { useQuery } from "@tanstack/react-query";
import { Dropdown } from "react-bootstrap";

import NewDashboardLayout from "@/layout/NewDashboardLayout";
import EventBudgetBreakdown from "@/components/EventBudgetBreakdown";
import { getAllVendors, getBudget, getEvents } from "@/axios/get-request";
import BudgetTrackerLoader from "@/components/loaders/BudgetTrackerLoader";
import { useEvent } from "@/hooks/useEvent";
import BudgetCard from "@/components/BudgetCard";
import AddBudgetCategoryModal from "@/components/modals/AddBudgetCategoryModal";
import { convertNumberToCurrency } from "@/helper";
import WeightIcon from "@/assets/icon/WeightIcon";
import BankNoteIcon from "@/assets/icon/BankNoteIcon";
import CoinsIcon from "@/assets/icon/CoinsIcon";
import HandCoinsIcon from "@/assets/icon/HandCoinsIcon";
import DefaultView from "@/components/DefaultView";
import FilterDropdown from "@/components/FilterDropdown";
import { useBudget } from "@/hooks/useBudget";
import useMyEvents from "@/hooks/useMyEvents";
import type { budgetType, categoryType } from "@/types";
import "@/styles/budget_tracker_new.scss";

enum ModalList {
    create_budget_category_modal = "create_budget_category_modal",
    create_new_event_modal = "create_new_event_modal",
    delete_budget_category_modal = "delete_budget_category_modal",
    add_budget_category_modal = "add_budget_category_modal",
    filter_by_ceremony_modal = "filter_by_ceremony_modal",
}

function getBudgetCostItemTotals(budgetCategories: budgetType["categories"], activeCeremonyId: string) {
    const costItemTotals = {
        estimate: 0,
        actual_cost: 0,
        paid: 0,
    };

    if (budgetCategories?.length > 0) {
        budgetCategories.forEach((category) => {
            if (activeCeremonyId !== "all") {
                const costItemCategory = category.cost_items.filter((item) => item.tag === activeCeremonyId);
                costItemCategory.forEach((item) => {
                    costItemTotals.estimate += item.estimate;
                    costItemTotals.actual_cost += item.actual;
                    costItemTotals.paid += item.paid;
                });
            } else {
                category.cost_items.forEach((item) => {
                    costItemTotals.estimate += item.estimate;
                    costItemTotals.actual_cost += item.actual;
                    costItemTotals.paid += item.paid;
                });
            }
        });
    }

    return costItemTotals;
}

export default function NewBudgetTrackerPage() {
    const { selectedEventId, activeCeremonyId, setActiveCeremonyId } = useEvent();
    const { data: eventsData } = useMyEvents();
    const [modal, setModal] = useState<keyof typeof ModalList | null>(null);
    const { budget, setBudget, setBudgetCategoryId } = useBudget();
    const navigate = useNavigate();
    const { data: vendorData } = useQuery({
        queryKey: ["all_event_vendors"],
        queryFn: () => getAllVendors(),
    });

    const { data, status } = useQuery({
        queryKey: [`get_budget_categories_${selectedEventId}_${activeCeremonyId}`],
        queryFn: () => getBudgetCategoriesRequest(selectedEventId),
    });

    async function getBudgetCategoriesRequest(selectedEventId: string) {
        const data = await getBudget(selectedEventId);
        if (data?.data?.result) {
            setBudget(data.data.result);
        }
        return data;
    }

    function updateEventBudgetHandler(eventIndex: number) {
        if (eventsData?.result && eventsData?.result[eventIndex]?.budget && eventsData?.result[eventIndex]?.budget?.categories[0]?.id) {
            setBudget(eventsData?.result[eventIndex]?.budget as budgetType);
            setBudgetCategoryId(eventsData?.result[eventIndex]?.budget?.categories[0]?.id as string);
            setActiveCeremonyId("all");
        }
    }

    const { data: eventData } = useQuery({
        queryKey: [`get_event_${selectedEventId ?? ""}`],
        queryFn: () => getEvents(),
    });

    const categoriesArray: categoryType[] | null = status === "success" && budget?.categories ? budget.categories : null;

    const costItemDetails = getBudgetCostItemTotals(budget?.categories as Array<categoryType>, activeCeremonyId);

    useEffect(() => {
        if (data?.data?.result && status === "success") {
            if (budget) {
                setBudget({
                    ...data.data.result,
                    ...costItemDetails,
                });
                setBudgetCategoryId(data?.data?.result?.categories[0]?.id);
            }
        }
    }, [status]);

    function onCloseModalHandler() {
        setModal(null);
    }

    const totalEstimate = costItemDetails.estimate ? convertNumberToCurrency(costItemDetails.estimate) : 0;
    const totalPaid = costItemDetails.paid ? convertNumberToCurrency(costItemDetails.paid) : 0;
    const totalActualCost = costItemDetails.actual_cost ? convertNumberToCurrency(costItemDetails.actual_cost) : 0;

    let __balance = 0;

    if (budget) {
        __balance = costItemDetails.actual_cost - costItemDetails.paid;
    }

    const balance = __balance ? convertNumberToCurrency(__balance) : 0;
    const currentEvent = selectedEventId && eventData?.result ? eventData?.result?.filter((event) => event.id === selectedEventId)[0] : null;
    const currentCeremony =
        currentEvent && activeCeremonyId && currentEvent?.ceremonies
            ? currentEvent.ceremonies.filter((ceremony) => ceremony.id === activeCeremonyId)[0]
            : null;
    const disableCeremony = currentEvent === null || currentEvent?.ceremonies?.length < 1;

    const eventCeremonies = currentEvent && currentEvent?.ceremonies ? currentEvent?.ceremonies : null;

    const currentCeremonyName = activeCeremonyId === "all" ? "All" : currentCeremony?.name ? currentCeremony?.name : "";

    function showCreateBudgetCategoryModalHandler() {
        if (!currentEvent) {
            return toast.error("Please create an event");
        }
        setModal(ModalList.create_budget_category_modal);
    }

    function navigateToVendors() {
        return navigate("/new/dashboard/vendors");
    }

    const categories = data?.data?.result.categories ? data?.data?.result.categories : null;

    return (
        <>
            <Suspense>
                {ModalList.create_budget_category_modal === modal && (
                    <AddBudgetCategoryModal budgetCategories={categoriesArray} onClose={onCloseModalHandler} />
                )}
            </Suspense>
            <NewDashboardLayout
                title="Event Budget"
                description="Manage your event's costs, estimate, breakdown & vendors."
                className="budget_tracker"
                onUpdateEventHandler={updateEventBudgetHandler}>
                {status === "loading" ? (
                    <BudgetTrackerLoader />
                ) : (
                    <>
                        <section className="event_summary_overview">
                            <div className="event_budget_row">
                                <div className="button_group">
                                    <FilterDropdown title={currentCeremonyName} disabled={disableCeremony}>
                                        {currentEvent && currentEvent?.ceremonies && (
                                            <Dropdown.Item onClick={() => setActiveCeremonyId("all")}>All</Dropdown.Item>
                                        )}
                                        {currentEvent &&
                                            currentEvent?.ceremonies.length > 0 &&
                                            currentEvent?.ceremonies?.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => setActiveCeremonyId(item.id)}>
                                                    {item.name}
                                                </Dropdown.Item>
                                            ))}
                                    </FilterDropdown>
                                    <div className="right_button_group">
                                        <button className="vendor" onClick={navigateToVendors}>
                                            Vendors
                                            <span>{vendorData?.result?.length}</span>
                                        </button>
                                        {/* <SiteDropdown className="custom three_dot" title={<ThreedotIcon />}>
                                            <Dropdown.Item>Edit Budget</Dropdown.Item>
                                            <Dropdown.Item>Add Payment</Dropdown.Item>
                                        </SiteDropdown> */}
                                    </div>
                                </div>
                            </div>
                            <div className="summary">
                                <BudgetCard
                                    price={totalEstimate}
                                    icon={<WeightIcon />}
                                    title="Budget"
                                    className="first"
                                    info="An estimate of the total cost of your event"
                                />
                                <BudgetCard
                                    icon={<HandCoinsIcon />}
                                    price={totalActualCost}
                                    className="second"
                                    title="Total Event Cost"
                                    info="Sum of total costs agreed with event vendors"
                                />
                                <BudgetCard icon={<CoinsIcon />} price={totalPaid} title="Total Paid" className="third" info="Total amount paid" />
                                <BudgetCard
                                    icon={<BankNoteIcon />}
                                    price={balance}
                                    className="fourth"
                                    title="Total Balance"
                                    info="Event total balance"
                                />
                            </div>
                        </section>
                        {categoriesArray && Array.isArray(categoriesArray) && categoriesArray.length > 0 ? (
                            <EventBudgetBreakdown
                                categories={categories}
                                addBudgetCategoryHandler={showCreateBudgetCategoryModalHandler}
                                ceremonies={eventCeremonies}
                            />
                        ) : (
                            <DefaultView
                                title="You have no Budget categories yet."
                                description="Create categories and assign cost items to them"
                                buttonText="Create Categories"
                                onClickHandler={showCreateBudgetCategoryModalHandler}
                            />
                        )}
                    </>
                )}
            </NewDashboardLayout>
        </>
    );
}
