import { useNavigate } from "react-router-dom";
import { addMonths, format } from "date-fns";
import { useState } from "react";

import NewOnboardingLayout from "@/layout/NewOnboardingLayout";
import payStackConfig from "@/payStackConfig";
import useAuth from "@/hooks/useAuth";
import CheckIcon2 from "@/assets/icon/CheckIcon2";
import { initializePaymentType, Pricing } from "@/data/types";
import { usePaystackPayment } from "react-paystack";
import { subscribeToPlan } from "@/axios/post-request";
import "@/styles/pricing.scss";

enum PlanPeriodList {
    Monthly = "Monthly",
    Annual = "Annual",
}

enum supportedCountriesList {
    Nigeria = "Nigeria",
    Ghana = "Ghana",
}

enum PlanList {
    STARTER = "STARTER",
    ESSENTIALS = "ESSENTIALS",
    PRO = "PRO",
}

type SupportedCountriesType = keyof typeof supportedCountriesList;
type PricingPlanType = keyof typeof PlanList;

// const supportedCountries: Array<SupportedCountriesType> = ["Nigeria", "Ghana"];

const plans = [
    {
        title: "",
        key: PlanList.PRO,
        description: "Access to all tools and more",
        pricing: {
            Nigeria: {
                Monthly: "₦15,000",
                Annual: "₦15,000",
            },
            Ghana: {
                Monthly: "₵150",
                Annual: "₵150",
            },
        },
        benefits: [
            { text: "Budget Tracker", available: true },
            { text: "Checklist", available: true },
            { text: "Event Timeline", available: true },
            // { text: "Event Day Flow", available: true },
            { text: "Floor Plan", available: true },
            { text: "New Features e.g Event Day Flow", available: true },
        ],
    },
];

export default function PlannerPricing() {
    const navigate = useNavigate();
    const [activeCountry] = useState<SupportedCountriesType>("Nigeria");
    const [pricingPlan, setPricingPlan] = useState<PricingPlanType | null>("ESSENTIALS");
    const nextBillingDate = format(addMonths(new Date(), 1), "do MMMM, yyyy");
    const { getAuthDetails } = useAuth();
    const authDetails = getAuthDetails();
    const startDate = addMonths(new Date(), 1);
    const email = authDetails?.currentUser?.email as string;
    const config = payStackConfig(email, Pricing.Test_account_validity);
    const initializePayment = usePaystackPayment(config) as initializePaymentType;

    async function makePaymentHandler() {
        try {
            initializePayment(() => {
                return subscribeToPlanHandler();
            });
        } catch (error) {
            console.log("error", error);
        }
    }

    async function subscribeToPlanHandler() {
        const subcribeRequest = await subscribeToPlan({
            email,
            startDate: startDate.toISOString(),
        });
        if (subcribeRequest.data.status) {
            return navigate("/new/dashboard");
        }
    }

    const planPeriod = PlanPeriodList.Monthly;
    const periodLowercase = planPeriod.toLowerCase();

    function onSelectPricingPlanHandler(plan: PricingPlanType) {
        setPricingPlan(plan);
    }

    return (
        <NewOnboardingLayout className="pricing_page planner" text="Start planning your" text2="dream event today...">
            <div className="content pricing">
                <div className="pricing_wrapper planner">
                    <div className="page_title">
                        <h2>Pricing</h2>
                        <p>Subscribe to Planaday</p>
                    </div>
                    <div className="row_1">
                        <div className="left">
                            <h3>1 month free trial</h3>
                            <p>After your free trial which ends on the {nextBillingDate} you will auto-enroll in our monthly plan</p>
                        </div>
                        {/* <SiteDropdown title={activeCountry} className="custom">
                            {supportedCountries.map((item, index) => (
                                <Dropdown.Item key={index} onClick={() => selectCountryHandler(item)}>
                                    {item}
                                </Dropdown.Item>
                            ))}
                        </SiteDropdown> */}
                    </div>
                    <div className="all_plans">
                        <div className="plans mt-4">
                            {plans.map((plan, index) => {
                                const className = index === 0 ? "essentials" : "pro";
                                const pricing = plan.pricing[activeCountry] ? plan.pricing[activeCountry][planPeriod] : "";
                                const activePlanClassName = pricingPlan === plan.key ? "active" : "";

                                return (
                                    <div
                                        key={index}
                                        className={`${className} ${activePlanClassName} plan_card`}
                                        onClick={() => onSelectPricingPlanHandler(plan.key)}>
                                        <div className="card_top">
                                            <h6>{plan.title}</h6>
                                            <p>{plan.description}</p>
                                        </div>
                                        <h5>
                                            {pricing} <span>/ {periodLowercase}</span>
                                        </h5>
                                        <ul className="benefits">
                                            {plan.benefits.map((benefit, idx) => {
                                                const benefitClassname = benefit.available ? "available_benefit" : "not_available_benefit";
                                                return (
                                                    <li key={idx} className={benefitClassname}>
                                                        {benefit.available ? (
                                                            <CheckIcon2 fill="#606060" />
                                                        ) : (
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                strokeWidth="2"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round">
                                                                <path d="M18 6 6 18" />
                                                                <path d="m6 6 12 12" />
                                                            </svg>
                                                        )}
                                                        {benefit.text}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <div className="note">1 month free ✨</div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="button_group mt-5">
                        <button className="prev_btn" onClick={() => navigate("/new/onboarding-event-planner/stage-1")}>
                            Previous
                        </button>
                        <button className="next_btn" onClick={makePaymentHandler}>
                            Start free trial
                        </button>
                    </div>
                </div>
            </div>
        </NewOnboardingLayout>
    );
}
